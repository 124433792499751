import { useState } from 'react';

import LoadingCircle from 'components/_shared/LoadingCircle';

import { fetchFile, fetchSession } from 'api/photo';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        closeBtnContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 0 20px 0',
            width: '98%'
        },
        closeBtn: {
            borderRadius: '36px',
            color: '#7378E8',
            fontSize: '18px',
            fontWeight: 800,
            padding: '5px 20px',
            textTransform: 'none'
        },
        closeIcon: {
            fontSize: '24px'
        },
        title: {
            fontSize: '24px',
            fontWeight: 800,
            padding: '20px 30px'
        },
        QRContainer: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'rgba(227, 227, 250, 0.5)',
            borderRadius: '16px',
            padding: '10px',
            width: '160px'
        },
        QRCode: {
            borderRadius: '8px',
            height: 'auto',
            width: '100%'
        },
        instructionContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 20px'
        },
        doneBtnContainer: {
            paddingBottom: '10px'
        },
        errorMessage: {
            textAlign: 'center',
            fontSize: '12px'
        }
    })
);

type QRContentProps = {
    handleModalClick: () => void;
    setProfilePicture: (file: File) => void;
    qrCode: string;
    setShowQR: (showQR: boolean) => void;
    token: string;
};

const QRContent: React.FC<QRContentProps> = ({
    handleModalClick,
    setProfilePicture,
    qrCode,
    setShowQR,
    token
}) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState<boolean | null>(null);

    const convertQRCode = (qrCode: string) => {
        const byteCharacters = atob(qrCode.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
        const url = URL.createObjectURL(blob);

        return url;
    };

    const handleCloseClick = () => {
        handleModalClick();
        setShowQR(false);
    };

    const handleDoneClick = async () => {
        setLoading(true);

        const { intake_session_token } = await fetchSession(token);

        if (intake_session_token.photo) {
            const file = await fetchFile(
                intake_session_token.photo,
                'profile_picture.jpg',
                'image/jpeg'
            );

            if (file) {
                setProfilePicture(file);
                setLoading(false);
                setUploaded(true);
                handleModalClick();
            }
        } else {
            setLoading(false);
        }

        setUploaded(false);
    };

    const instructions = [
        `1. Scan the QR code using your phone's camera`,
        `2. Follow the instructions in the opened link`,
        `3. Click "done" only when you've confirmed the selected profile photo on the link opened on your phone`
    ];

    return (
        <div className={classes.content}>
            <div className={classes.closeBtnContainer}>
                <span className={classes.title}>
                    Mobile Photo Upload Instructions
                </span>
                <Button onClick={() => handleCloseClick()}>
                    <i className={`far fa-times ${classes.closeIcon}`} />
                </Button>
            </div>
            <div className={classes.QRContainer}>
                {qrCode === '' ? (
                    <LoadingCircle />
                ) : (
                    <img
                        className={classes.QRCode}
                        src={convertQRCode(qrCode)}
                        alt="QR Code"
                    />
                )}
            </div>
            <div className={classes.instructionContainer}>
                <List>
                    {instructions.map((instruction, index) => (
                        <ListItem key={index} style={{ padding: '0 16px' }}>
                            <ListItemText primary={instruction} />
                        </ListItem>
                    ))}
                </List>
                {uploaded === false ? (
                    <div className={classes.errorMessage}>
                        <span style={{ color: 'red', fontWeight: '700' }}>
                            Photo was not successfully uploaded
                        </span>
                        <br />
                        Please finish the process or try again
                    </div>
                ) : null}
            </div>
            <div className={classes.doneBtnContainer}>
                <Button
                    className={classes.closeBtn}
                    onClick={() => handleDoneClick()}
                    // disabled={!profilePicture}
                >
                    {loading ? <LoadingCircle /> : 'Done'}
                </Button>
            </div>
        </div>
    );
};

export default QRContent;
