import { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// components
import AppErrorBoundary from './AppErrorBoundary';
import ErrorPage from '../error';
import FourOhFour from './FourOhFour';
import HoldersList from 'containers/HoldersList';
import HolderProfile from 'containers/HolderProfile';
import Intake from 'containers/Intake';
import Login from 'containers/Login';
import MobilePhotoUpload from 'components/intake/Account/components/MobilePhotoUpload';
import Referrals from 'containers/Referrals';
import Settings from 'containers/Settings';

import LogoutRoute from './LogoutRoute';
import PrivateRoute from './PrivateRoute';
import Routes from 'routes';

import AuthManager from '../../auth';
import { featureToggleContext } from '../_util_components/FeatureToggle';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import './App.scss';

import customTheme from '../_theme';

interface AppProps {
    email: string;
    fetchUser: (userId: number) => void;
    getApiError: boolean;
    getApiErrorResponse: {
        message: string;
    };
    id: number;
    last_name: string;
    name: string;
    unauthorizedUser: boolean;
}

class App extends Component<AppProps> {
    static contextType = featureToggleContext;

    componentDidMount() {
        const { id } = this.props;
        const userId = AuthManager.getInstance().getUserID();

        if (!id && userId) {
            this.props.fetchUser(parseInt(userId));
        }
    }

    render() {
        const {
            getApiError,
            getApiErrorResponse,
            unauthorizedUser,
            email,
            name,
            last_name
        } = this.props;

        if (getApiError) {
            // TODO: error route
            console.log('API Error Response: ', getApiErrorResponse);

            if (getApiErrorResponse && getApiErrorResponse.message) {
                console.log(
                    `API Error Message: Bad request: ${getApiErrorResponse.message}`
                );
            }
        } else if (unauthorizedUser) {
            var auth = AuthManager.getInstance();
            auth.unsetToken();
        }

        const isStaging =
            window.location.hostname === 'partner.staging.samaritan.city';

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={customTheme}>
                    <AppErrorBoundary>
                        <Router>
                            <Helmet
                                defaultTitle="Samaritan Partner"
                                titleTemplate="%s | Samaritan Partner"
                            >
                                {isStaging && (
                                    <meta
                                        name="robots"
                                        content="noindex, nofollow"
                                    />
                                )}
                            </Helmet>
                            <Switch>
                                <Route
                                    exact
                                    path={Routes.login}
                                    component={Login}
                                />
                                <Route
                                    exact
                                    path={Routes.error}
                                    component={ErrorPage}
                                />
                                <Route
                                    exact
                                    path={Routes.photo}
                                    component={MobilePhotoUpload}
                                />
                                <PrivateRoute
                                    exact
                                    path={Routes.root}
                                    component={HoldersList}
                                    unauthorizedUser={unauthorizedUser}
                                    email={email}
                                    name={name}
                                    last_name={last_name}
                                />
                                <PrivateRoute
                                    exact
                                    path={Routes.holder_list}
                                    component={HoldersList}
                                    unauthorizedUser={unauthorizedUser}
                                    email={email}
                                    name={name}
                                    last_name={last_name}
                                />
                                <PrivateRoute
                                    path={Routes.holder_profile}
                                    component={HolderProfile}
                                    unauthorizedUser={unauthorizedUser}
                                    email={email}
                                    name={name}
                                    last_name={last_name}
                                />
                                <PrivateRoute
                                    exact
                                    path={Routes.intake}
                                    component={Intake}
                                    unauthorizedUser={unauthorizedUser}
                                    email={email}
                                    name={name}
                                    last_name={last_name}
                                />
                                <PrivateRoute
                                    exact
                                    path={Routes.referrals}
                                    component={Referrals}
                                    unauthorizedUser={unauthorizedUser}
                                    email={email}
                                    name={name}
                                    last_name={last_name}
                                />
                                <PrivateRoute
                                    exact
                                    path={Routes.settings}
                                    component={Settings}
                                    unauthorizedUser={unauthorizedUser}
                                    email={email}
                                    name={name}
                                    last_name={last_name}
                                />
                                <LogoutRoute />
                                <Route component={FourOhFour} />
                            </Switch>
                        </Router>
                    </AppErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

// TODO: Fix App type as any
export default withCookies(App as any);
