import Modal2 from 'components/_shared/Modal2';
import ReferCard from './ReferCard';

import { Caseload, Homeless } from 'types';

type PopupReferMemberProps = {
    handleCloseClick: () => void;
    referCaseload: Caseload;
    referHolder: Homeless;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (caseloadID: number, memberID: number) => void;
};

const PopupReferMember: React.FC<PopupReferMemberProps> = ({
    handleCloseClick,
    referCaseload,
    referHolder,
    removeMemberFromMyMembers,
    saveMemberToMyMembers
}) => {
    return (
        <Modal2
            onClose={handleCloseClick}
            title="Refer Member Info"
            width="440px"
        >
            <ReferCard
                handleCloseClick={handleCloseClick}
                referCaseload={referCaseload}
                referHolder={referHolder}
                removeMemberFromMyMembers={removeMemberFromMyMembers}
                saveMemberToMyMembers={saveMemberToMyMembers}
            />
        </Modal2>
    );
};

export default PopupReferMember;
