import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    GoalActions,
    Homeless,
    NewGoalActions,
    TouchpointAnswers,
    TouchpointQuestions,
    User
} from 'types';

import ActionCard from './components/ActionCard';
import AddActionStep from './components/AddActionStep';
import AddButton from '../components/AddButton';
import BonusTracker from '../BonusTracker';
import Card from '../components/Card';
import LoadingCard from '../components/LoadingCard';
import PopUp from '../../PopUp';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    actionStepsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    }
}));

type ActionStepsProps = {
    assignedBonusAmount: number;
    goalActions: GoalActions[];
    holder: Homeless;
    isEditingGoalAction: boolean;
    maxBonusAmount: number;
    newHomelessGoalActions: NewGoalActions[];
    remainingBonusAmount: number;
    setShowToast: (value: boolean) => void;
    setToastMessage: (value: string) => void;
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
    touchpointQuestionQOLs: number[];
    user: User;
};

const ActionSteps: React.FC<ActionStepsProps> = ({
    assignedBonusAmount,
    goalActions,
    holder,
    isEditingGoalAction,
    maxBonusAmount,
    newHomelessGoalActions,
    remainingBonusAmount,
    setShowToast,
    setToastMessage,
    touchpointAnswers,
    touchpointQuestions,
    touchpointQuestionQOLs,
    user
}) => {
    const classes = useStyles();
    const goalActionState = useSelector((state: any) => state.goalActions);

    const {
        isPostingGoalAction,
        isPostingGoalActionErrored,
        isPostingGoalActionSuccess
    } = goalActionState;

    const [add, setAdd] = useState(false);
    const [addFailed, setAddFailed] = useState(false);
    const [actionStepID, setActionStepID] = useState<null | number>(null);
    const [completeActionStep, setCompleteActionStep] = useState(false);
    const [dateISO, setDateISO] = useState('');
    const [edit, setEdit] = useState(false);
    const [editAction, setEditAction] = useState<any>(null);
    const [failedAction, setFailedAction] = useState<NewGoalActions | null>(
        null
    );
    const [hasQOLMeasures, setHasQOLMeasures] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [reminderAction, setReminderAction] = useState<null | string>(null);
    const [showActionOverlay, setShowActionOverlay] = useState(false);
    const [updatedActionIDs, setUpdatedActionIDs] = useState<number[]>([]);
    const [updateAction, setUpdateAction] = useState<GoalActions | null>(null);
    const [updateSDOHStatus, setUpdateSDOHStatus] = useState(false);

    const pendingActionSteps = goalActions.filter(
        (action) =>
            action.status === 'pending' || updatedActionIDs.includes(action.id)
    );

    const shouldRenderSparkle = (index: number) =>
        index === pendingActionSteps.length - 1 &&
        isPostingGoalActionSuccess &&
        newHomelessGoalActions[newHomelessGoalActions.length - 1]?.status ===
            'successful';

    const addProps = {
        addFailed,
        cancelAction: setAdd,
        dateISO,
        failedAction,
        holder,
        setAddFailed,
        setDateISO,
        setFailedAction,
        setShowActionOverlay,
        showActionOverlay,
        userID: user.id
    };

    const editProps = {
        cancelAction: setEdit,
        dateISO,
        setDateISO,
        editAction,
        setEditAction,
        isEditingGoalAction,
        setActionStepID,
        updatedActionIDs,
        setUpdatedActionIDs
    };

    useEffect(() => {
        if (edit && actionStepID) {
            const action = pendingActionSteps.find(
                (action) => action.id === actionStepID
            );

            setEditAction(action);
        }
    }, [actionStepID, edit, pendingActionSteps]);

    useEffect(() => {
        if (reminder && actionStepID) {
            const action = pendingActionSteps.find(
                (action) => action.id === actionStepID
            );

            if (action) {
                setReminderAction(action.description);
            }
        }
    }, [pendingActionSteps, actionStepID, reminder, reminderAction]);

    useEffect(() => {
        if (updateSDOHStatus && actionStepID) {
            const action = pendingActionSteps.find(
                (action) => action.id === actionStepID
            );

            if (action) {
                setUpdateAction(action);
            }
        }
    }, [pendingActionSteps, actionStepID, updateSDOHStatus]);

    useEffect(() => {
        if (
            !isPostingGoalAction &&
            isPostingGoalActionErrored &&
            newHomelessGoalActions.length
        ) {
            setAdd(true);
            setAddFailed(true);
            setFailedAction(
                newHomelessGoalActions[newHomelessGoalActions.length - 1]
            );
            setShowActionOverlay(true);
        }
    }, [
        isPostingGoalAction,
        isPostingGoalActionErrored,
        newHomelessGoalActions
    ]);

    return (
        <div className={classes.actionStepsContainer}>
            <Card info action count={pendingActionSteps.length} />
            <BonusTracker
                assignedBonusAmount={assignedBonusAmount}
                holder={holder}
                maxBonusAmount={maxBonusAmount}
                remainingBonusAmount={remainingBonusAmount}
            />
            {pendingActionSteps.map((action, index) => (
                <ActionCard
                    key={index}
                    action={action}
                    isLast={shouldRenderSparkle(index)}
                    name={holder.name}
                    setActionStepID={setActionStepID}
                    setCompleteActionStep={setCompleteActionStep}
                    setEdit={setEdit}
                    setHasQOLMeasures={setHasQOLMeasures}
                    setReminder={setReminder}
                    setShowToast={setShowToast}
                    setToastMessage={setToastMessage}
                    touchpointQuestionQOLs={touchpointQuestionQOLs}
                    updatedActionIDs={updatedActionIDs}
                />
            ))}
            {isPostingGoalAction ? <LoadingCard /> : null}
            {add ? (
                <AddActionStep
                    from="profile"
                    orgID={user.partner.id}
                    remainingBonusAmount={remainingBonusAmount}
                    {...addProps}
                />
            ) : (
                <AddButton
                    action={() => setAdd(true)}
                    remainingBonusAmount={remainingBonusAmount}
                    type="action step"
                />
            )}
            {completeActionStep && (
                <PopUp
                    content="complete action step"
                    actionStepID={actionStepID}
                    handleModalClick={setCompleteActionStep}
                    hasQOLMeasures={hasQOLMeasures}
                    holder={holder}
                    setUpdatedActionIDs={setUpdatedActionIDs}
                    setUpdateSDOHStatus={setUpdateSDOHStatus}
                    updatedActionIDs={updatedActionIDs}
                />
            )}
            {edit && (
                <PopUp
                    content="edit action step"
                    actionStepID={actionStepID}
                    handleModalClick={setEdit}
                    holder={holder}
                    maxBonusAmount={maxBonusAmount}
                    remainingBonusAmount={remainingBonusAmount}
                    totalBonusAmount={holder.action_step_bonus_monthly_total}
                    {...editProps}
                />
            )}
            {reminder && (
                <PopUp
                    content="reminder"
                    actionStepID={actionStepID}
                    handleModalClick={setReminder}
                    holder={holder}
                    reminderAction={reminderAction}
                    setReminder={setReminder}
                />
            )}
            {updateSDOHStatus && updateAction && (
                <PopUp
                    content="update SDOH status"
                    handleModalClick={setUpdateSDOHStatus}
                    holder={holder}
                    setUpdateSDOHStatus={setUpdateSDOHStatus}
                    touchpointAnswers={touchpointAnswers}
                    touchpointQuestions={touchpointQuestions}
                    updateAction={updateAction}
                />
            )}
        </div>
    );
};

export default ActionSteps;
