import { dateToFormattedString } from '../../../../../../util';

import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        answer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            border: '2px solid #E3E3FA',
            padding: '16px',
            borderRadius: '16px',
            position: 'relative'
        },
        date: {
            fontSize: '12px',
            fontWeight: 400,
            color: '#A6A6BF'
        },
        editIcon: {
            position: 'absolute',
            top: '-12px',
            right: '-12px',
            padding: '0',
            minWidth: '40px',
            minHeight: '40px',
            borderRadius: 40,
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            color: '#1F2152'
        }
    })
);

type Props = {
    answer: string;
    date: string;
    setAddAnswer?: Function;
};

const Answer: React.FC<Props> = ({ answer, date, setAddAnswer }) => {
    const classes = useStyles();

    return (
        <div className={classes.answer}>
            <Button
                className={classes.editIcon}
                onClick={() => {
                    if (setAddAnswer) {
                        setAddAnswer(true);
                    }
                }}
            >
                <i className="far fa-pencil" />
            </Button>
            <span>"{answer}"</span>
            <span className={classes.date}>
                Answered on: {dateToFormattedString(new Date(date))}
            </span>
        </div>
    );
};

export default Answer;
