import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import ActionButtons from './ActionButtons';
import ActionStepSparkle from 'components/_shared/ActionStepSparkle';
import Card from '../../components/Card';
import LoadingCard from '../../components/LoadingCard';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    actionStepCard: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    title: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '25px'
    },
    description: {
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '21px'
    },
    bonus: {
        backgroundColor: '#E3E3FA',
        borderRadius: '20px',
        fontSize: '16px',
        fontWeight: 800,
        lineHeight: '21px',
        padding: '6px 16px',
        width: 'fit-content'
    },
    markdownTitle: {
        '& p': {
            margin: '0'
        }
    },
    actionStepsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '8px',
        marginTop: '16px',
        justifyContent: 'space-between',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            gap: '8px'
        }
    }
}));

type ActionCardProps = {
    action: any;
    isLast: boolean;
    name: string;
    setActionStepID: (id: number) => void;
    setCompleteActionStep: (complete: boolean) => void;
    setEdit: (edit: boolean) => void;
    setHasQOLMeasures: (hasQOLMeasures: boolean) => void;
    setReminder: (reminder: boolean) => void;
    setShowToast: (value: boolean) => void;
    setToastMessage: (value: string) => void;
    touchpointQuestionQOLs: any;
    updatedActionIDs: number[];
};

const ActionCard: React.FC<ActionCardProps> = ({
    action,
    isLast,
    name,
    setActionStepID,
    setCompleteActionStep,
    setEdit,
    setHasQOLMeasures,
    setReminder,
    setShowToast,
    setToastMessage,
    touchpointQuestionQOLs,
    updatedActionIDs
}) => {
    const classes = useStyles();

    const {
        description,
        due_at,
        goal,
        id,
        match_value,
        quality_of_life,
        requestState,
        status
    } = action;
    const isUpdated = updatedActionIDs.includes(id);

    useEffect(() => {
        if (isUpdated && status === 'completed') {
            setShowToast(true);

            setToastMessage(
                match_value
                    ? `$${match_value} bonus added to ${name}'s Funds`
                    : 'Action Step Completed'
            );

            const timeoutId = setTimeout(() => {
                setShowToast(false);
            }, 5000);

            return () => clearTimeout(timeoutId);
        }
    }, [isUpdated, match_value, name, setShowToast, setToastMessage, status]);

    return (
        <>
            {requestState === 'loading' ? (
                <LoadingCard />
            ) : (
                <Card
                    id="action-card"
                    backgroundColor={isUpdated ? '#FBFBFF' : ''}
                >
                    <div className={classes.actionStepCard}>
                        {isLast && <ActionStepSparkle />}
                        <ReactMarkdown
                            className={`${classes.markdownTitle} ${classes.title}`}
                            children={description}
                        />
                        <div className={classes.description}>
                            {quality_of_life && quality_of_life.description
                                ? quality_of_life.description
                                : goal.description}
                        </div>
                    </div>
                    <div
                        className={classes.actionStepsBottom}
                        style={isUpdated ? { justifyContent: 'flex-end' } : {}}
                    >
                        {!isUpdated && (
                            <div className={classes.bonus}>
                                {match_value ? `$${match_value}` : `No Bonus`}
                            </div>
                        )}
                        <ActionButtons
                            action={action}
                            date={due_at}
                            setActionStepID={setActionStepID}
                            setCompleteActionStep={setCompleteActionStep}
                            setEdit={setEdit}
                            setHasQOLMeasures={setHasQOLMeasures}
                            setReminder={setReminder}
                            touchpointQuestionQOLs={touchpointQuestionQOLs}
                            updatedActionIDs={updatedActionIDs}
                        />
                    </div>
                </Card>
            )}
        </>
    );
};

export default ActionCard;
