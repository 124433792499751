import { ReactNode } from 'react';

import Modal2 from 'components/_shared/Modal2';
import UpdateStatus from '../UpdateStatus';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: '16px',
            padding: '30px'
        }
    })
);

type SDOHPopUpProps = {
    content: ReactNode | string;
    catchUpID?: number;
    handleModalClick: () => void;
    updateStatus: boolean;
    setAddStatus?: (value: boolean) => void;
    setUpdateStatus: (value: boolean) => void;
    updateStatusProps: {
        answers: object[];
        id: number;
        name: null | string;
        statusId: null | number;
        statusQuestions: object[];
    };
};

const SDOHPopUp: React.FC<SDOHPopUpProps> = ({
    content,
    catchUpID,
    handleModalClick,
    updateStatus,
    setAddStatus,
    setUpdateStatus,
    updateStatusProps
}) => {
    const classes = useStyles();

    const { answers, id, name, statusId, statusQuestions } = updateStatusProps;

    return (
        <Modal2 onClose={handleModalClick} title="SDOH Status">
            {!updateStatus || !statusId ? (
                <span className={classes.content}>{content}</span>
            ) : (
                <UpdateStatus
                    answers={answers}
                    catchUpID={catchUpID}
                    id={id}
                    name={name || ''}
                    setAddStatus={setAddStatus}
                    setUpdateStatus={setUpdateStatus}
                    statusId={statusId || 0}
                    questions={statusQuestions}
                />
            )}
        </Modal2>
    );
};

export default SDOHPopUp;
