import { useState } from 'react';

import { Deployments } from 'types';

import {
    BirthdayInput,
    Input,
    NameInput,
    NickNameInput,
    OrgInput,
    PhoneInput,
    StatusLabel
} from './components/inputs';
import Card from 'components/_shared/Card';
import CardBackground from './components/CardBackground';
import MessageBubble from 'components/_shared/MessageBubble';
import ProfilePhoto from './components/ProfilePhoto';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        accountContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '16px',
            height: 'fit-content',
            margin: '0 auto 50px auto',
            padding: '50px 0 0 0',
            width: '90%',
            maxWidth: '720px',
            '@media (max-width: 768px)': {
                width: '90%'
            }
        },
        accountCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '50px',
            width: '80%',
            zIndex: 1
        },
        accountCardBody: {
            width: '90%',
            margin: 'auto',
            padding: '20px'
        },
        btn: {
            borderRadius: '36px',
            color: '#7378E8',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '24px',
            fontWeight: 700,
            padding: '5px 20px',
            textTransform: 'none',
            '& i': {
                fontSize: '20px'
            },
            '& span': {
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
            }
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '80%',
            marginTop: '20px'
        },
        btnPrimary: {
            background: 'linear-gradient(90deg, #9897E3 0%, #B8C2FF 100%)',
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '32px',
            fontWeight: 800,
            margin: 'auto',
            padding: '10px 80px',
            textTransform: 'none',
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)'
            },
            '& span': {
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
            },
            '& i': {
                fontSize: '40px'
            }
        },
        inputTitle: {
            color: '#1F1F51',
            fontFamily: 'Manrope',
            fontWeight: 600,
            letterSpacing: '0.15em'
        },
        inputTitleDisabled: {
            color: '#A6A6BF'
        }
    })
);

type AccountProps = {
    deployments: Deployments[];
    incrementStep: (progressBarWidth: number) => void;
    intakeBirthDay: string;
    intakeDeploymentId: number;
    intakeEmail: string;
    intakePhoneNo: string;
    intakePrivateFirstName: string;
    intakePrivateFullName: string;
    intakePrivateLastName: string;
    intakePrivateMiddleName: string;
    intakeProfileName: string;
    intakeProfilePic: File | null;
    intakeRelevantId: string;
    progressBarWidth: number;
    remoteCopy: any;
    setGuide: (guide: boolean) => void;
    setIntakeBirthDay: (intakeBirthDay: string) => void;
    setIntakeDeploymentId: (intakeDeploymentId: number) => void;
    setIntakeEmail: (intakeEmail: string) => void;
    setIntakePhoneNo: (intakePhoneNo: string) => void;
    setIntakePrivateFirstName: (intakePrivateFirstName: string) => void;
    setIntakePrivateFullName: (intakePrivateFullName: string) => void;
    setIntakePrivateLastName: (intakePrivateLastName: string) => void;
    setIntakePrivateMiddleName: (intakePrivateMiddleName: string) => void;
    setIntakeProfileName: (intakeProfileName: string) => void;
    setIntakeProfilePicture: (profilePic: File | null) => void;
    setIntakeRelevantId: (intakeRelevantId: string) => void;
};

const Account: React.FC<AccountProps> = ({
    deployments,
    incrementStep,
    intakeBirthDay,
    intakeDeploymentId,
    intakeEmail,
    intakePhoneNo,
    intakePrivateFirstName,
    intakePrivateFullName,
    intakePrivateLastName,
    intakePrivateMiddleName,
    intakeProfileName,
    intakeProfilePic,
    intakeRelevantId,
    progressBarWidth,
    remoteCopy,
    setGuide,
    setIntakeBirthDay,
    setIntakeDeploymentId,
    setIntakeEmail,
    setIntakePhoneNo,
    setIntakePrivateFirstName,
    setIntakePrivateFullName,
    setIntakePrivateLastName,
    setIntakePrivateMiddleName,
    setIntakeProfileName,
    setIntakeProfilePicture,
    setIntakeRelevantId
}) => {
    const classes = useStyles();

    const { account } = remoteCopy.remoteCopy.pages;

    const [nickname, setNickname] = useState(intakeProfileName);

    const inputsMap = {
        email: intakeEmail,
        phone_number: intakePhoneNo,
        date_of_birth: intakeBirthDay,
        full_name: intakePrivateFullName,
        identification_number: intakeRelevantId,
        profile_picture: intakeProfilePic,
        public_name: intakeProfileName || nickname.length > 0
    } as {
        [key: string]: any;
    };

    const requiredInputs = Object.keys(account).reduce(
        (acc: any, key: string) => {
            if (typeof account[key] === 'object') {
                const keys = Object.keys(account[key]);
                const required = keys.filter((k) => account[key][k].required);
                return [...acc, ...required];
            }
            return acc;
        },
        []
    );

    const nonGrandfatheredDeployments =
        deployments?.filter((deployment) => !deployment.is_grandfathered) || [];

    const inputTitleClass = (input: string) => {
        return input && input.length === 0
            ? classes.inputTitle
            : `${classes.inputTitle} ${classes.inputTitleDisabled}`;
    };

    const nextButtonArrow = () => {
        const isComplete =
            intakePrivateFullName &&
            intakeBirthDay &&
            intakeRelevantId &&
            intakeEmail &&
            intakePhoneNo;

        return (
            <i
                className={
                    isComplete
                        ? 'fas fa-long-arrow-right'
                        : 'fas fa-chevron-right'
                }
            />
        );
    };

    const nextButtonClass = () => {
        const isComplete =
            intakePrivateFullName &&
            intakeBirthDay &&
            intakeRelevantId &&
            intakeEmail &&
            intakePhoneNo;

        return isComplete ? classes.btnPrimary : classes.btn;
    };

    const onContinue = () => {
        const container = document.getElementById('intake-container');

        if (container) {
            container.scrollTop = 0;
        }

        incrementStep(progressBarWidth);
    };

    const renderDisabled = () => {
        if (nonGrandfatheredDeployments.length > 1 && !intakeDeploymentId) {
            return true;
        }

        if (requiredInputs.some((input: string) => !inputsMap[input])) {
            return true;
        }

        return false;
    };

    const validateEmail = (input: string) => {
        const validEmailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return validEmailRegex.test(input);
    };

    const privateNameProps = {
        inputTitleClass,
        intakePrivateFirstName,
        intakePrivateFullName,
        intakePrivateLastName,
        intakePrivateMiddleName,
        setIntakePrivateFirstName,
        setIntakePrivateFullName,
        setIntakePrivateLastName,
        setIntakePrivateMiddleName
    };

    const profilePhotoProps = {
        inputTitleClass,
        profilePicture: intakeProfilePic,
        setGuide,
        setProfilePicture: setIntakeProfilePicture
    };

    const publicNameProps = {
        inputTitleClass,
        intakeProfileName,
        nickname,
        setIntakeProfileName,
        setNickname
    };

    return (
        <div className={classes.accountContainer}>
            <CardBackground />
            <MessageBubble question={account.private_info.bubble} />
            <Card className={classes.accountCard}>
                <StatusLabel isPrivate={true} />
                <div className={classes.accountCardBody}>
                    <NameInput
                        remoteCopy={account.private_info.full_name}
                        {...privateNameProps}
                    />
                    <BirthdayInput
                        inputTitleClass={inputTitleClass}
                        intakeInput={intakeBirthDay}
                        remoteCopy={account.private_info.date_of_birth}
                        setIntakeInput={setIntakeBirthDay}
                    />
                    {nonGrandfatheredDeployments.length > 1 ? (
                        <OrgInput
                            deployment={nonGrandfatheredDeployments}
                            intakeInput={intakeDeploymentId}
                            inputTitleClass={inputTitleClass}
                            // remoteCopy
                            setIntakeInput={setIntakeDeploymentId}
                        />
                    ) : null}
                    <Input
                        inputTitleClass={inputTitleClass}
                        intakeInput={intakeRelevantId}
                        remoteCopy={account.private_info.identification_number}
                        setIntakeInput={setIntakeRelevantId}
                    />
                </div>
            </Card>
            <MessageBubble question={account.contact_info.bubble} />
            <Card className={classes.accountCard}>
                <StatusLabel isPrivate={true} />
                <div className={classes.accountCardBody}>
                    <Input
                        inputTitleClass={inputTitleClass}
                        intakeInput={intakeEmail}
                        remoteCopy={account.contact_info.email}
                        setIntakeInput={setIntakeEmail}
                        validateFn={validateEmail}
                    />
                    <PhoneInput
                        inputTitleClass={inputTitleClass}
                        intakeInput={intakePhoneNo}
                        remoteCopy={account.contact_info.phone_number}
                        setIntakeInput={setIntakePhoneNo}
                    />
                </div>
            </Card>
            <MessageBubble question={account.public_info.bubble} />
            <Card className={classes.accountCard}>
                <StatusLabel isPrivate={false} />
                <div className={classes.accountCardBody}>
                    <NickNameInput
                        remoteCopy={account.public_info.public_name}
                        {...publicNameProps}
                    />
                    <ProfilePhoto
                        remoteCopy={account.public_info.profile_picture}
                        {...profilePhotoProps}
                    />
                </div>
            </Card>
            <div className={classes.btnContainer}>
                <Button
                    className={nextButtonClass()}
                    disabled={renderDisabled()}
                    onClick={() => onContinue()}
                >
                    Next Step
                    {nextButtonArrow()}
                </Button>
            </div>
        </div>
    );
};

export default Account;
