import { useState } from 'react';
import { useDispatch } from 'react-redux';

import AddButton from '../CaseManagement/components/AddButton';
import Card from '../CaseManagement/components/Card';
import Confetti from './components/Confetti';
import ContextField from 'components/_shared/ContextField';
import ImprovementCard from './components/ImprovementCard';
import Modal2 from 'components/_shared/Modal2';

import { submitTouchpointUpdateContext } from 'api/touchpointAnswers';
import { decrementImprovedStatusCount } from 'redux/actions/touchpointAnswers';

import { getHolderName } from 'util/index';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        body: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '24px',
            padding: '88px 32px 32px 32px',
            position: 'relative',
            '@media (max-width: 494px)': {
                padding: '88px 16px 32px 16px'
            }
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            background: '#FFFFFF',
            borderRadius: '16px'
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#9A9EE7',
            color: '#FFFFFF',
            width: '100%',
            padding: '36px 0',
            fontSize: '20px',
            borderRadius: '16px 16px 0 0',
            '& h2': {
                padding: '0 32px'
            }
        },
        homeIcon: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '-36px',
            left: 'calc(50% - 36px)',
            width: '72px',
            height: '72px',
            backgroundColor: '#9A9EE7',
            borderRadius: '400px',
            border: '3px solid #FFFFFF',
            color: '#FFFFFF',
            '& i': {
                fontSize: '32px'
            },
            '@media (max-width: 768px)': {
                top: '10px'
            }
        }
    })
);

type Props = {
    holder: {
        name: string;
    };
    previousAnswer: {
        classified_statuses: {
            classified_status_answer_matches: {
                rank: number;
            }[];
        }[];
    };
    setCelebrate: (celebrate: boolean) => void;
    touchpointQuestions: {
        id: number;
        icon_name: string;
    }[];
    updatedAnswer: {
        id: number;
        classified_statuses: {
            classified_status_answer_matches: {
                rank: number;
            }[];
        }[];
        touchpoint_question_id: number;
    };
};

const PopupCelebration: React.FC<Props> = ({
    holder,
    previousAnswer,
    setCelebrate,
    touchpointQuestions,
    updatedAnswer
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { name } = holder;
    const { id } = updatedAnswer;

    const oldStatus =
        previousAnswer?.classified_statuses[0]
            ?.classified_status_answer_matches[0]?.rank || 0;
    const newStatus =
        updatedAnswer?.classified_statuses[0]
            ?.classified_status_answer_matches[0]?.rank;
    const touchpointQuestionID = updatedAnswer?.touchpoint_question_id;
    const question = touchpointQuestions.find(
        (question) => question.id === touchpointQuestionID
    );
    const icon_name = question?.icon_name;

    const [addContext, setAddContext] = useState<boolean>(false);
    const [context, setContext] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [stopConfetti, setStopConfetti] = useState<boolean>(false);

    const handleContextClick = () => {
        setAddContext(!addContext);
        setStopConfetti(true);
    };

    const handleCloseClick = () => {
        dispatch(decrementImprovedStatusCount());
    };

    const handleSubmit = async (setSuccess: (arg0: boolean) => void) => {
        setLoading(true);

        try {
            await submitTouchpointUpdateContext(id, context);
        } catch (error) {
            console.error('Error submitting context', error);
        }

        setSuccess(true);
        setLoading(false);
        setContext('');

        setTimeout(() => {
            setAddContext(false);
            dispatch(decrementImprovedStatusCount());
        }, 2000);
    };

    return (
        <Modal2 onClose={handleCloseClick} title="Status Improvement">
            <Confetti stopConfetti={stopConfetti} />
            <span className={classes.homeIcon}>
                <i className={`fas fa-${icon_name}`} />
            </span>
            <div className={classes.content}>
                <span className={classes.header}>
                    <h2>Congrats on {getHolderName(name)} progress! 🎉</h2>
                </span>
                <div className={classes.body}>
                    <ImprovementCard
                        oldStatus={oldStatus}
                        newStatus={newStatus}
                        touchpointQuestionID={touchpointQuestionID}
                    />
                    <Card
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px'
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '16px',
                                fontSize: '18px'
                            }}
                        >
                            We’d love to hear more about what’s changed for
                            Chris and what helped!
                        </span>
                        {!addContext ? (
                            <AddButton
                                action={() => handleContextClick()}
                                style={{
                                    backgroundColor: 'transparent',
                                    padding: '30px',
                                    position: 'relative',
                                    justifyContent: 'flex-start',
                                    zIndex: 5,
                                    fontSize: '18px'
                                }}
                                type="context"
                            />
                        ) : (
                            <ContextField
                                context={context}
                                handleModalClick={setCelebrate}
                                handleSubmit={handleSubmit}
                                loading={loading}
                                setContext={setContext}
                            />
                        )}
                    </Card>
                </div>
            </div>
        </Modal2>
    );
};

export default PopupCelebration;
