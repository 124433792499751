import { useEffect, useState } from 'react';

import Modal2 from 'components/_shared/Modal2';
import PhotoCrop from './components/PhotoCrop';
import QRContent from './components/QRContent';
import WebcamContent from './components/WebcamContent';

import { createSessionToken } from 'api/photo';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            background: '#FBFBFF',
            border: 'none',
            borderBottom: '1px solid #A6A6BF',
            borderRadius: '0',
            color: '#1F1F51',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
            padding: '20px 36px',
            textTransform: 'capitalize',
            width: '100%',
            '&:hover': {
                backgroundColor: '#F2F2FD'
            },
            '& input[type="file"]': {
                display: 'none'
            }
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '16px',
            overflow: 'hidden'
        },
        computerBtn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0',
            width: '100%',
            '& label': {
                cursor: 'pointer'
            }
        },
        fileUpload: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: '20px 0px',
            width: '100%'
        }
    })
);

type PhotoPopupProps = {
    fromProfilePhoto?: boolean;
    handleModalClick: () => void;
    profilePhotoURL?: string;
    setProfilePicture: (profilePicture: any) => void;
    setGuide?: (guide: boolean) => void;
};

const PhotoPopup: React.FC<PhotoPopupProps> = ({
    fromProfilePhoto,
    handleModalClick,
    profilePhotoURL,
    setProfilePicture,
    setGuide
}) => {
    const classes = useStyles();

    const [edit, setEdit] = useState(false);
    const [computerImage, setComputerImage] = useState<File | null>(null);
    const [qrCode, setQRCode] = useState('');
    const [showQR, setShowQR] = useState(false);
    const [showWebcam, setShowWebcam] = useState(false);
    const [token, setToken] = useState('');

    const handleDownloadImage = () => {
        if (profilePhotoURL) {
            window.open(profilePhotoURL, '_blank');
        } else {
            console.log('No image to download');
        }
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const img = e.target.files?.[0];

        if (img) {
            setComputerImage(img);
        }

        if (setGuide) {
            setGuide(false);
        }
    };

    const handleMobileClick = async () => {
        const { intake_session_token } = await createSessionToken();

        if (setGuide) {
            setGuide(false);
        }

        setShowQR(true);
        setToken(intake_session_token.token);
        setQRCode(intake_session_token.qr_code);
    };

    const handleWebcamClick = () => {
        if (setGuide) {
            setGuide(false);
        }

        setShowWebcam(true);
    };

    useEffect(() => {
        if (showQR) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showQR]);

    const showEditActions = !edit && fromProfilePhoto;
    const showUploadOptions =
        !showQR && !showWebcam && !computerImage && (!fromProfilePhoto || edit);

    return (
        <Modal2
            onClose={handleModalClick}
            title="Profile Picture"
            width="480px"
        >
            {showEditActions && (
                <div className={classes.content}>
                    <Button
                        className={classes.btn}
                        onClick={() => setEdit(true)}
                    >
                        Edit
                    </Button>
                    <Button
                        className={classes.btn}
                        onClick={handleDownloadImage}
                    >
                        Download
                    </Button>
                    <Button
                        className={classes.btn}
                        onClick={() => handleModalClick()}
                    >
                        Cancel
                    </Button>
                </div>
            )}
            {showUploadOptions && (
                <div className={classes.content}>
                    <div className={`${classes.btn} ${classes.computerBtn}`}>
                        <label className={classes.fileUpload}>
                            From Computer
                            <input
                                accept="image/*"
                                id="photo-upload"
                                onChange={handleImageUpload}
                                title=" "
                                type="file"
                            />
                        </label>
                    </div>
                    <Button className={classes.btn} onClick={handleMobileClick}>
                        From Mobile
                    </Button>
                    <Button className={classes.btn} onClick={handleWebcamClick}>
                        From Webcam
                    </Button>
                    <Button
                        className={classes.btn}
                        onClick={() => handleModalClick()}
                    >
                        Cancel
                    </Button>
                </div>
            )}
            {computerImage && (
                <PhotoCrop
                    handleModalClick={handleModalClick}
                    image={computerImage}
                    setProfilePicture={setProfilePicture}
                />
            )}
            {showQR && (
                <QRContent
                    handleModalClick={handleModalClick}
                    setProfilePicture={setProfilePicture}
                    qrCode={qrCode}
                    setShowQR={setShowQR}
                    token={token}
                />
            )}
            {showWebcam && (
                <WebcamContent
                    handleModalClick={handleModalClick}
                    setProfilePicture={setProfilePicture}
                    setShowWebcam={setShowWebcam}
                />
            )}
        </Modal2>
    );
};

export default PhotoPopup;
