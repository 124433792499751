import { Fragment, useState } from 'react';

import UpdateSDOHStatus from './UpdateStatusWrapper';

import { formatDate, getColor } from '../../CaseManagement/SDOHStatus/utils';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        updateContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            backgroundColor: '#EDEDFA',
            borderRadius: '16px 16px 0 0',
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 800,
            padding: '24px 0',
            width: '100%'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            gap: '16px',
            width: '90%',
            padding: '24px 0'
        },
        action: {
            fontWeight: 800
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            marginTop: '10px'
        },
        updateBtn: {
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '36px',
            color: '#FFFFFF',
            textTransform: 'none',
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 800,
            padding: '16px 40px',
            margin: '10px 0 30px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        cancelBtn: {
            background: 'transparent',
            border: '1px solid #7378E8',
            color: '#7378E8'
        },
        titleIconContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        statusBar: {
            minWidth: '3px',
            borderRadius: '3px'
        },
        statusDate: {
            fontSize: '12px',
            fontWeight: 400,
            color: '#A6A6BF',
            lineHeight: '24px'
        },
        statusDescription: {
            display: 'flex',
            fontSize: '14px',
            fontWeight: 400,
            color: '#1F2152',
            lineHeight: '24px',
            width: '100%'
        },
        statusIcon: {
            marginRight: '12px',
            fontSize: '22px',
            color: '#1F2152'
        },
        statusItem: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%'
        },
        statusText: {
            fontSize: '16px',
            fontWeight: 700,
            color: '#1F2152',
            lineHeight: '24px'
        },
        descriptionBody: {
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            margin: '0 40px 0 10px'
        },
        divider: {
            width: '100%',
            height: '1px',
            backgroundColor: '#E3E3FA',
            borderRadius: '1px',
            margin: '5px 0'
        }
    })
);

type Props = {
    action: any;
    catchUpItemID?: number;
    handleCloseClick: () => void;
    homelessID: number | null;
    name: string;
    need: object;
    touchpointAnswers: any[];
    touchpointQuestions: any[];
};

const Update: React.FC<Props> = ({
    action,
    catchUpItemID,
    handleCloseClick,
    homelessID,
    name,
    need,
    touchpointAnswers,
    touchpointQuestions
}) => {
    const classes = useStyles();

    const [update, setUpdate] = useState(false);

    const { quality_of_life_measures } = action || need;
    const possessiveSuffix = name.endsWith('s') ? `${name}'` : `${name}'s`;

    const qolIDs = quality_of_life_measures.map(
        (qol: { id: number }) => qol.id
    );
    const answers = touchpointAnswers.filter((answer) =>
        qolIDs.includes(answer.quality_of_life_measure_id)
    );
    const questions = touchpointQuestions.filter((question) =>
        qolIDs.includes(question.quality_of_life_measure_id)
    );

    const renderColor = (answer: {
        answer_index: number | null;
        classified_statuses: [
            { classified_status_answer_matches: [{ rank: number }] }
        ];
        question_type: string;
    }) => {
        if (answer && answer.question_type === 'StatusQuestion') {
            const answers =
                answer.classified_statuses[0]?.classified_status_answer_matches;

            if (answers && answers.length) {
                const highestAnswer = answers.reduce((prev, curr) =>
                    curr.rank > prev.rank ? curr : prev
                );

                return getColor(highestAnswer.rank - 1, 'primary', 5);
            }
            return '#999DFF';
        }

        if (answer.answer_index !== null) {
            return getColor(answer.answer_index, 'primary', 5);
        } else {
            return '#999DFF';
        }
    };

    const renderAnswers = () => {
        return questions.map((question, index) => {
            const { friendly_name, icon_name, quality_of_life_measure_id } =
                question;
            const answer =
                answers.find(
                    (answer) =>
                        answer.quality_of_life_measure_id ===
                        quality_of_life_measure_id
                ) || {};

            const statusBarColor = answer?.answers
                ? renderColor(answer.answers[0])
                : renderColor(answer) || '#999DFF';

            return (
                <Fragment key={index}>
                    <div className={classes.statusItem}>
                        <div className={classes.titleIconContainer}>
                            <i
                                className={`far fa-${icon_name} ${classes.statusIcon}`}
                            />
                            <span className={classes.statusText}>
                                {friendly_name}
                            </span>
                        </div>
                        <div className={classes.statusDescription}>
                            <div
                                className={classes.statusBar}
                                style={{
                                    backgroundColor: statusBarColor
                                }}
                            />
                            <div className={classes.descriptionBody}>
                                <span>
                                    {answer.answers
                                        ? answer.answers[0].answer
                                        : answer.answer}
                                </span>
                                <span className={classes.statusDate}>
                                    On{' '}
                                    {formatDate(
                                        answer.answers
                                            ? answer.answers[0].created_at
                                            : answer.created_at
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    {index < questions.length - 1 && (
                        <div className={classes.divider} />
                    )}
                </Fragment>
            );
        });
    };

    return (
        <div className={classes.updateContainer}>
            {!update ? (
                <>
                    <span className={classes.header}>
                        Did any of {possessiveSuffix} statuses change?
                    </span>
                    <div className={classes.body}>{renderAnswers()}</div>
                    <div className={classes.btnContainer}>
                        <Button
                            className={`${classes.updateBtn} ${classes.cancelBtn}`}
                            onClick={() => handleCloseClick()}
                        >
                            No
                        </Button>
                        <Button
                            className={classes.updateBtn}
                            onClick={() => setUpdate(true)}
                        >
                            Yes, let's update it
                        </Button>
                    </div>
                </>
            ) : (
                <UpdateSDOHStatus
                    action={action}
                    answers={answers}
                    catchUpItemID={catchUpItemID}
                    homelessID={homelessID || 0}
                    name={name}
                    // need={need}
                    setUpdateStatus={setUpdate}
                    questions={questions}
                />
            )}
        </div>
    );
};

export default Update;
