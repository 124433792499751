import { ActionStep, ActionStepTemplate } from 'types';

import Card from 'components/_shared/Card';

import EditIcon from 'assets/icon-edit.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    actionCount: {
        position: 'absolute',
        left: '20px',
        top: '-12px',
        backgroundColor: '#8882D8',
        borderRadius: '100px',
        color: '#fff',
        fontWeight: '800',
        letterSpacing: '0.1em',
        padding: '0.25rem 1rem',
        textTransform: 'uppercase'
    },
    actionDetails: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    actionDetailsContainer: {
        display: 'flex',
        gap: '8px',
        color: '#5A6A81'
    },
    actionStepCard: {
        position: 'relative',
        borderRadius: '20px',
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        padding: '20px',
        width: '90%'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        paddingRight: '16px',
        width: '100%'
    },
    description: {
        fontSize: '20px',
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    editIconContainer: {
        position: 'absolute',
        top: '30%',
        right: '-25px',
        zIndex: 2,
        display: 'flex',
        background: '#fff',
        borderRadius: '100px',
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        padding: '12px',
        '& img': {
            height: '20px'
        },
        '& #iconEdit': {
            cursor: 'pointer'
        },
        '&:active': {
            transform: 'scale(0.95)'
        }
    },
    '@global': {
        '#summary-card': {
            margin: '20px 0 !important'
        }
    }
});

type ActionStepCardProps = {
    action: ActionStep;
    actionType?: ActionStep[];
    count?: number;
    customStep?: boolean;
    handleEditClick?: (index: number) => void;
    index?: number;
    intakeGoals?: ActionStep[];
    remainingBonusAmount?: number;
    temporaryAction?: ActionStep | ActionStepTemplate | null;
};

const ActionStepCard: React.FC<ActionStepCardProps> = ({
    action,
    actionType,
    count,
    customStep,
    handleEditClick,
    index,
    intakeGoals,
    temporaryAction
}) => {
    const classes = useStyles();

    const amount = action.bonus ? action.bonus : action.amount;

    const renderCost = (bonus: number) => {
        if (bonus === 0 || !bonus) {
            return '- bonus';
        } else {
            return `${bonus} bonus`;
        }
    };

    const renderDueAt = (due_at: number | string) => {
        if (typeof due_at === 'string') {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            let dueDate: Date;

            if (due_at.length <= 10) {
                dueDate = new Date(due_at + 'T00:00:00');
            } else if (due_at.length > 10) {
                dueDate = new Date(due_at);
            } else {
                dueDate = new Date(today);
                dueDate.setDate(today.getDate() + parseInt(due_at));
            }

            dueDate.setHours(0, 0, 0, 0);

            const diffTime = Math.abs(dueDate.getTime() - today.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= 3) {
                return `ASAP`;
            } else {
                return `${diffDays} Days`;
            }
        } else {
            if (due_at === 0 || !due_at) {
                return '- days';
            } else {
                return `${due_at} days`;
            }
        }
    };

    return (
        <Card className={classes.actionStepCard} id="summary-card">
            {actionType && actionType.length !== 0 && index === 0 && (
                <span className={classes.actionCount}>
                    {count} ACTION STEPS
                </span>
            )}
            {(handleEditClick &&
                index &&
                intakeGoals &&
                intakeGoals[index].default !== true) ||
            (handleEditClick && index && temporaryAction) ? (
                <div
                    className={classes.editIconContainer}
                    data-edit={index}
                    onClick={() => handleEditClick(index)}
                >
                    <img alt="edit-icon" id="iconEdit" src={EditIcon} />
                </div>
            ) : null}
            <div
                className={classes.cardBody}
                style={index === 0 ? { paddingTop: '8px' } : {}}
            >
                <span
                    className={classes.description}
                    style={customStep ? { color: '#A6A6BF' } : {}}
                >
                    {action.description}
                </span>
                <div
                    className={classes.actionDetailsContainer}
                    style={customStep ? { color: '#A6A6BF' } : {}}
                >
                    {(amount > 0 || customStep) && (
                        <span className={classes.actionDetails}>
                            <i className="fal fa-usd-circle" />$
                            {renderCost(amount)}
                        </span>
                    )}
                    {((amount > 0 && action.due_at) || customStep) && (
                        <span>•</span>
                    )}
                    {(action.due_at || customStep) && (
                        <span className={classes.actionDetails}>
                            <i className="fal fa-clock" />
                            {renderDueAt(action.due_at)}
                        </span>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default ActionStepCard;
