import { Jimp } from 'jimp';
import piexif from 'piexifjs';
import { useState } from 'react';

import BlurredPic from 'assets/webcam-ph-blurred.png';
import LoadingCircle from 'components/_shared/LoadingCircle';
import Logo from 'assets/logo.png';
import ProfilePic from 'assets/webcam-ph.jpeg';

import { uploadPhoto } from 'api/photo';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            background: '#1d1d48',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            height: '100vh',
            padding: '0 20px'
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: '20px',
            '& img': {
                height: 'auto',
                width: '60px'
            }
        },
        uploadContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.25)',
            margin: '20px',
            padding: '20px',
            '& input[type=file]::-webkit-file-upload-button': {
                display: 'none'
            },
            '& input[type=file]': {
                display: 'none',
                color: 'transparent',
                marginTop: '10px',
                textAlignLast: 'center'
            }
        },
        title: {
            color: '#1F1F51',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '33px'
        },
        subtitle: {
            color: '#1F1F51',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '19px',
            margin: '12px 0'
        },
        uploadButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
            borderRadius: '36px',
            padding: '0.5rem 4rem',
            textTransform: 'none',
            color: '#fff',
            fontSize: '16px',
            fontWeight: 700
        },
        retakeButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '36px',
            padding: '0.5rem 4rem',
            textTransform: 'none',
            '& span': {
                color: '#7378E8',
                fontSize: '16px',
                fontWeight: 700,
                marginBottom: '0'
            }
        },
        disabledButton: {
            background: '#E3E3FA'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            paddingTop: '10px',
            '& i': {
                fontSize: '24px'
            }
        },
        success: {
            background: 'linear-gradient(180deg, #7FE882 0%, #42DD47 100%)'
        },
        errorText: {
            color: '#FF0000',
            fontSize: '14px',
            fontWeight: 700,
            margin: '10px 0',
            textAlign: 'center'
        },
        switchLabel: {
            '& .MuiFormControlLabel-label': {
                color: '#1F1F51',
                fontSize: '14px',
                fontWeight: 700
            }
        },
        photoContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        profilePhoto: {
            borderRadius: '100%',
            height: '100px',
            objectFit: 'cover',
            padding: '10px',
            width: '100px '
        },
        editBtnContainer: {
            position: 'absolute',
            alignSelf: 'flex-end',
            padding: '0 0 10px 120px',
            '& div': {
                margin: '0',
                padding: '0'
            }
        },
        filterContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '20px',
            margin: '15px 0'
        },
        choiceContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            '& i': {
                color: '#EDEDFA',
                fontSize: '24px'
            }
        },
        filterTitle: {
            color: '#1F1F51',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '21px'
        },
        filterPic: {
            borderRadius: '16px',
            height: '140px',
            objectFit: 'cover',
            width: '140px',
            '&:hover': {
                cursor: 'pointer'
            },
            '&:active': {
                transform: 'scale(0.95)'
            },
            '@media (max-width: 768px)': {
                height: '120px',
                width: '120px'
            }
        },
        previewPic: {
            borderRadius: '16px',
            height: '180px',
            objectFit: 'cover',
            width: '180px'
        },
        checkedIcon: {
            color: '#7378E8 !important'
        },
        circleIcon: {
            cursor: 'pointer',
            '&:active': {
                transform: 'scale(0.95)'
            }
        }
    })
);

const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file); // This reads the file as base64
    });
};

const MobilePhotoUpload = () => {
    const classes = useStyles();
    const city = window.location.href.split('&subdomain=')[1];

    const [blur, setBlur] = useState<boolean | null>(null);
    const [error, setError] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState<null | File>(null);
    const [uploaded, setUploaded] = useState(false);
    const [usePhoto, setUsePhoto] = useState(false);

    const getTokenFromURL = (url: string) => {
        const urlSearchParams = new URLSearchParams(url.split('?')[1]);

        return urlSearchParams.get('token');
    };

    const dataURItoBlob = (dataURI: string) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    const handleImageUpload = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const img: File | undefined = e.target.files?.[0];
        const token: string = getTokenFromURL(window.location.href) || '';

        try {
            setLoading(true);

            if (!blur && img) {
                const res = await uploadPhoto(token, city, img);

                if (res.status === 200 || res.status === 201) {
                    setPhoto(img);
                    setUploaded(true);
                } else {
                    setBlur(null);
                    setError(`Error: ${res.status}. Please try again`);
                    setPhoto(null);
                }
            } else {
                if (!img) return;

                const base64Image = await fileToBase64(img);

                try {
                    const exifObj = piexif.load(base64Image);
                    const orientation =
                        exifObj['0th'][piexif.ImageIFD.Orientation];

                    console.log('orientation', orientation);

                    const arrayBuffer = await img.arrayBuffer();
                    const blurredImg = await Jimp.read(arrayBuffer);

                    if (orientation === 3) {
                        blurredImg.rotate(180);
                    } else if (orientation === 6) {
                        blurredImg.rotate(-90);
                    } else if (orientation === 8) {
                        blurredImg.rotate(90);
                    }

                    blurredImg.blur(100);

                    const base64Data: string =
                        await blurredImg.getBase64('image/jpeg');
                    const blobData: Blob = dataURItoBlob(base64Data);
                    const file: File = new File(
                        [blobData],
                        'blurredPhoto.png',
                        {
                            type: 'image/jpeg'
                        }
                    );

                    const res = await uploadPhoto(token, city, file);

                    if (res.status === 200 || res.status === 201) {
                        setPhoto(file);
                        setUploaded(true);
                    } else {
                        setBlur(null);
                        setError(`Error: ${res.status}. Please try again`);
                        setPhoto(null);
                    }
                } catch (exifError) {
                    setError('Error reading EXIF data. Please try again.');
                    console.error(exifError);
                }
            }
        } catch (error: any) {
            setError(`Error uploading photo: ${error}. Please try again.`);
        } finally {
            setLoading(false);

            if (e.target) {
                e.target.value = '';
            }
        }
    };

    const handleRemoveUpload = () => {
        const fileInput = document.querySelector(
            '#photo-upload'
        ) as HTMLInputElement;

        if (fileInput) {
            fileInput.value = ``;
        }

        setUploaded(false);
        setPhoto(null);
    };

    const renderButtonClass = () => {
        return blur !== null
            ? classes.uploadButton
            : `${classes.disabledButton} ${classes.uploadButton}`;
    };

    const renderButtonText = () => {
        if (loading) {
            return <LoadingCircle />;
        }

        return uploaded ? 'Success!' : 'Take a photo';
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.logoContainer}>
                <img src={Logo} alt="logo" />
            </div>
            {!uploaded ? (
                <div className={classes.uploadContainer}>
                    <span className={classes.title}>
                        Take a member's picture
                    </span>
                    <span className={classes.subtitle}>
                        If they'd like to opt for more privacy, please use the
                        'blur' option to mask their face in the picture
                    </span>
                    <div className={classes.filterContainer}>
                        <div className={classes.choiceContainer}>
                            <span className={classes.filterTitle}>Regular</span>
                            <img
                                className={classes.filterPic}
                                src={ProfilePic}
                                alt="regular-profile"
                                onClick={() => setBlur(false)}
                            />
                            {blur === false ? (
                                <i
                                    className={`fas fa-check-circle ${classes.checkedIcon}`}
                                />
                            ) : (
                                <i
                                    className={`fal fa-circle ${classes.circleIcon}`}
                                    onClick={() => {
                                        if (error) {
                                            setError(null);
                                        }

                                        setBlur(false);
                                    }}
                                />
                            )}
                        </div>
                        <div className={classes.choiceContainer}>
                            <span className={classes.filterTitle}>Blurred</span>
                            <img
                                className={classes.filterPic}
                                src={BlurredPic}
                                alt="blurred-profile"
                                onClick={() => setBlur(true)}
                            />
                            {blur ? (
                                <i
                                    className={`fas fa-check-circle ${classes.checkedIcon}`}
                                />
                            ) : (
                                <i
                                    className={`fal fa-circle ${classes.circleIcon}`}
                                    onClick={() => {
                                        if (error) {
                                            setError(null);
                                        }

                                        setBlur(true);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <input
                        accept="image/*"
                        disabled={blur === null}
                        id="photo-upload"
                        onChange={(e) => handleImageUpload(e)}
                        title=" "
                        type="file"
                    />
                    {error && <div className={classes.errorText}>{error}</div>}
                    <div className={classes.buttonContainer}>
                        <label htmlFor="photo-upload">
                            <Button
                                className={renderButtonClass()}
                                component="span"
                                disabled={
                                    (error && error.length > 0) ||
                                    loading ||
                                    blur === null
                                }
                            >
                                {renderButtonText()}
                            </Button>
                        </label>
                    </div>
                </div>
            ) : (
                <div className={classes.uploadContainer}>
                    <div className={classes.photoContainer}>
                        {photo && (
                            <img
                                alt="uploaded"
                                className={classes.previewPic}
                                src={URL.createObjectURL(photo)}
                            />
                        )}
                    </div>
                    <div className={classes.buttonContainer}>
                        {!usePhoto ? (
                            <label htmlFor="photo-upload">
                                <Button
                                    className={classes.uploadButton}
                                    component="span"
                                    disabled={loading}
                                    onClick={() => setUsePhoto(true)}
                                >
                                    Use this photo
                                </Button>
                                <Button
                                    className={classes.retakeButton}
                                    component="span"
                                    disabled={loading || blur === null}
                                    onClick={() => handleRemoveUpload()}
                                >
                                    Retake
                                </Button>
                            </label>
                        ) : (
                            <i
                                className={`fas fa-check-circle ${classes.checkedIcon}`}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobilePhotoUpload;
