import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E76B6C',
        borderRadius: 25,
        color: '#FFFFFF',
        fontSize: 20,
        padding: 25,
        maxWidth: 600
    },
    icon: {
        marginRight: 25,
        '&::before': {
            fontSize: 40
        }
    }
});

const Alert = ({ text }: { text: string }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <i className={`fal fa-exclamation-circle ${classes.icon}`} />
            <span style={{ textAlign: 'center' }}>{text}</span>
        </div>
    );
};

export default Alert;
