import { useEffect, useRef } from 'react';

import { ActionStepTemplate } from 'types';

import Card from 'components/_shared/Card';
import FormInput from 'components/_shared/FormInput';
import Overlay from 'components/_shared/Overlay';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    actionDetails: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    actionDetailsContainer: {
        display: 'flex',
        gap: '8px',
        color: '#5A6A81'
    },
    bodyWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    customDescriptionInput: {
        backgroundColor: 'transparent',
        border: 'none',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: 700,
        outline: 'none',
        resize: 'none',
        width: '100%',
        '&::placeholder': {
            color: '#A6A6BF'
        }
    },
    description: {
        fontSize: '20px',
        fontWeight: 700
    },
    templateBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '8px',
        paddingRight: '16px',
        width: '100%'
    },
    templateCard: {
        position: 'relative',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 !important',
        '&:active': {
            transform: 'scale(0.98)'
        }
    }
});

type TemplateCardProps = {
    active?: boolean;
    customDescription?: string;
    disabled?: boolean;
    onClick?: (cost: number, due_at: number, description: string) => void;
    overlay?: boolean;
    setCustomDescription?: (description: string) => void;
    template: ActionStepTemplate;
};

const TemplateCard: React.FC<TemplateCardProps> = ({
    active,
    customDescription,
    disabled,
    onClick,
    setCustomDescription,
    overlay,
    template
}) => {
    const classes = useStyles();
    const customInputRef = useRef<HTMLTextAreaElement>(null);

    const { bonus, description, due_at } = template;
    const customStep = description === 'Write your own...';

    const handleCardClick = () => {
        if (!customStep && disabled) return;

        if (onClick) {
            onClick(bonus, due_at, customDescription || description);
        }
    };

    const renderCost = (bonus: number) => {
        if (bonus === 0 || !bonus) {
            return '- bonus';
        } else {
            return `${bonus} bonus`;
        }
    };

    const renderDueAt = (due_at: number) => {
        if (due_at === 0 || !due_at) {
            return '- days';
        } else {
            return `${due_at} days`;
        }
    };

    const renderIcon = () => {
        if (customStep && !customDescription!.length) {
            return (
                <i className="fal fa-keyboard" style={{ color: '#A6A6BF' }} />
            );
        } else {
            return (
                <i
                    className="far fa-arrow-right"
                    style={{ alignSelf: 'center', color: '#999DFF' }}
                />
            );
        }
    };

    useEffect(() => {
        if (
            active &&
            customStep &&
            customDescription!.length === 0 &&
            customInputRef.current
        ) {
            customInputRef.current.focus();
        }
    }, [active, customStep, customDescription]);

    return (
        <Card
            className={classes.templateCard}
            active={!customStep}
            onClick={handleCardClick}
        >
            {overlay && <Overlay text="Action Step Added!" />}
            <div className={classes.bodyWrapper}>
                <div className={classes.templateBody}>
                    {customStep ? (
                        <FormInput
                            className={classes.customDescriptionInput}
                            customStep={customStep}
                            isEditing={{
                                cost: false,
                                due_date: false,
                                descriptions: false
                            }}
                            name="custom action step description"
                            onChange={(e) =>
                                setCustomDescription?.(e.target.value)
                            }
                            placeholder="Write your own..."
                            ref={customInputRef}
                            saveCustomInput={onClick}
                            template
                            value={customDescription}
                        />
                    ) : (
                        <span
                            className={classes.description}
                            style={customStep ? { color: '#A6A6BF' } : {}}
                        >
                            {description}
                        </span>
                    )}
                    <div
                        className={classes.actionDetailsContainer}
                        style={customStep ? { color: '#A6A6BF' } : {}}
                    >
                        {(bonus > 0 || customStep) && (
                            <span className={classes.actionDetails}>
                                <i className="fal fa-usd-circle" />$
                                {renderCost(bonus)}
                            </span>
                        )}
                        {((bonus > 0 && due_at > 0) || customStep) && (
                            <span>•</span>
                        )}
                        {(due_at > 0 || customStep) && (
                            <span className={classes.actionDetails}>
                                <i className="fal fa-clock" />
                                {renderDueAt(due_at)}
                            </span>
                        )}
                    </div>
                </div>
                {renderIcon()}
            </div>
        </Card>
    );
};

export default TemplateCard;
