import classNames from 'classnames';
import ReactGA from 'react-ga4';

import { SaveMemberProps } from 'types/interfaces';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        saveMemberButton: {
            alignSelf: 'flex-end',
            borderRadius: '40px',
            border: '1.6px solid #E3E3FA',
            color: '#1F2152',
            backgroundColor: '#FFFFFF',
            minHeight: '38.5px',
            padding: '6px 20px',
            fontWeight: 500,
            textTransform: 'none',
            zIndex: 4,
            '& i': {
                marginRight: '10px'
            },
            '@media (max-width: 768px)': {
                fontSize: '12px',
                padding: '6px 12px'
            }
        },
        small: {
            padding: '6px 12px',
            minWidth: '0px'
        }
    })
);

const SaveMember: React.FC<SaveMemberProps> = ({
    activeReferral,
    caseload,
    caseloadID,
    createMemberCaseload,
    hasViewedTooltip,
    isSaved,
    memberID,
    removeMemberFromMyMembers,
    saved,
    saveMemberToMyMembers,
    setSaved,
    showTooltip
}) => {
    const classes = useStyles();

    const buttonText = saved ? 'Saved' : 'Save';

    const buttonClass = classNames({
        [classes.saveMemberButton]: true,
        [classes.small]: isSaved
    });

    const iconClass = classNames({
        'fas fa-bookmark': saved,
        'far fa-bookmark': !saved
    });

    const handleSaveClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Save Member Click'
        });

        setSaved(!saved);

        if (!caseloadID) {
            createMemberCaseload(memberID);
            return;
        }

        if (saved || isSaved) {
            removeMemberFromMyMembers(caseloadID);
            return;
        }

        const acceptReferral = activeReferral ? true : undefined;
        saveMemberToMyMembers(caseloadID, memberID, acceptReferral);
    };

    const renderSaveMemberButtonText = () => {
        if (activeReferral && !isSaved) {
            return (
                <>
                    <i className={iconClass} /> Accept Referral
                </>
            );
        }

        if (isSaved) {
            return <i className="fas fa-bookmark" style={{ margin: '0' }} />;
        }

        return (
            <>
                <i className={iconClass} />
                {buttonText} as "My Member"
            </>
        );
    };

    return (
        <Button
            className={buttonClass}
            onClick={handleSaveClick}
            disabled={!caseload && !hasViewedTooltip && showTooltip}
            style={showTooltip ? { color: '#1F2152' } : {}}
        >
            {renderSaveMemberButtonText()}
        </Button>
    );
};

export default SaveMember;
