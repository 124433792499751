import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Card from '../../Card';

import { resetIntake } from 'redux/actions/intake';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            display: 'flex',
            alignItems: 'center',
            flex: 'none',
            flexGrow: 0,
            gap: '8px',
            borderRadius: '20px',
            boxSizing: 'border-box',
            fontWeight: 800,
            height: '40px',
            order: 0,
            padding: '0px 32px',
            textTransform: 'none'
        },
        buttonCancel: {
            border: '1px solid #A80A2D',
            color: '#A80A2D'
        },
        buttonClose: {
            backgroundColor: '#A80A2D',
            color: '#FFFFFF'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        cardStyle: {
            margin: '0px !important',
            textAlign: 'center'
        },
        exitIntakeContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
        }
    })
);

type ExitIntakeProps = {
    onClose: () => void;
};

const ExitIntake: React.FC<ExitIntakeProps> = ({ onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const cancelButtonClass = classNames(classes.button, classes.buttonCancel);
    const closeButtonClass = classNames(classes.button, classes.buttonClose);

    const handleCardClose = () => {
        dispatch(resetIntake());
        history.push('/');
    };

    return (
        <div className={classes.exitIntakeContainer}>
            <Card className={classes.cardStyle}>
                <b>Are you sure you want to leave this page?</b>
                <br />
                <br />
                Intake progress will not be saved
            </Card>
            <div className={classes.buttonContainer}>
                <Button className={cancelButtonClass} onClick={onClose}>
                    Stay
                </Button>
                <Button className={closeButtonClass} onClick={handleCardClose}>
                    Leave
                </Button>
            </div>
        </div>
    );
};

export default ExitIntake;
