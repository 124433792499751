import { Fragment } from 'react';

import { ActionStep, ActionStepTemplate, RemoteCopy } from 'types';

import ActionStepCard from './ActionStepCard';
import ActionStepForm from './ActionStepForm';

import Fade from '@mui/material/Fade';

type ActionStepSummaryProps = {
    actionSuggestions: null | RemoteCopy;
    actionType: ActionStep[];
    editAction: number[];
    from: string;
    handleEditClick: (index: number) => void;
    intakeGoals: ActionStep[];
    remainingBonusAmount: number;
    setDisplayNeedBtns?: (displayNeedBtns: boolean) => void;
    setEditAction: (editAction: number[]) => void;
    setGuide: (guide: boolean) => void;
    setIntakeGoals: (intakeGoals: ActionStep[]) => void;
    setShowForm: (showForm: boolean) => void;
    showForm: boolean;
    temporaryAction: ActionStep | ActionStepTemplate | null;
};

const ActionStepSummary: React.FC<ActionStepSummaryProps> = ({
    actionSuggestions,
    actionType,
    editAction,
    from,
    handleEditClick,
    intakeGoals,
    remainingBonusAmount,
    setDisplayNeedBtns,
    setEditAction,
    setGuide,
    setIntakeGoals,
    setShowForm,
    showForm,
    temporaryAction
}) => {
    const count = actionType ? actionType.length : 0;

    const customStep = false;

    const editForm = (index: number) => {
        if (temporaryAction && index === intakeGoals.length) {
            return {
                actionIndex: index,
                costInputState:
                    temporaryAction.bonus || temporaryAction.amount || 0,
                dateInputState: temporaryAction.due_at,
                descriptionInput: true,
                descriptionInputState: temporaryAction.description
            };
        }

        return {
            actionIndex: index,
            costInputState: intakeGoals[index].bonus
                ? intakeGoals[index].bonus!
                : intakeGoals[index].amount,
            customGoalInputState: intakeGoals[index].customGoals,
            dateInputState: intakeGoals[index].due_at,
            descriptionInput: true,
            descriptionInputState: intakeGoals[index].description,
            goalInputState: intakeGoals[index].goals,
            qolMeasureState: intakeGoals[index].qolMeasure
        };
    };

    return (
        <>
            {actionType.map((action, index) => {
                if (editAction.includes(index)) {
                    return (
                        <Fade
                            in={!showForm}
                            timeout={500}
                            key={`edit-fade-${index}`}
                        >
                            <div style={{ width: '100%' }}>
                                <ActionStepForm
                                    actionSuggestions={actionSuggestions}
                                    editAction={editAction}
                                    editForm={editForm(index)}
                                    from={from}
                                    intakeGoals={intakeGoals}
                                    onClick={() => setDisplayNeedBtns!(true)}
                                    remainingBonusAmount={remainingBonusAmount}
                                    setEditAction={setEditAction}
                                    setGuide={setGuide}
                                    setIntakeGoals={setIntakeGoals}
                                    setShowForm={setShowForm}
                                    showEdit={true}
                                    showForm={showForm}
                                />
                            </div>
                        </Fade>
                    );
                }

                const cardContent = (
                    <ActionStepCard
                        action={action}
                        actionType={actionType}
                        count={count}
                        customStep={customStep}
                        handleEditClick={handleEditClick}
                        index={index}
                        intakeGoals={intakeGoals}
                    />
                );

                return <Fragment key={`fade-${index}`}>{cardContent}</Fragment>;
            })}
        </>
    );
};

export default ActionStepSummary;
