import { useCallback, useEffect, useState } from 'react';

import Add from '../CaseManagement/components/Add';
import AddInfo from './components/AddInfo';
import DefaultBody from './components/DefaultBody';
import Modal2 from 'components/_shared/Modal2';
import PhotoPopup from 'components/_shared/PhotoPopUp';
import PopUp from '../PopUp';
import UpdateStatus from '../CaseManagement/SDOHStatus/UpdateStatus';

import {
    Homeless,
    ProfileItems,
    TouchpointAnswers,
    TouchpointQuestions,
    User
} from 'types';

import useUpdateHomelessInfo from 'hooks/useUpdateHomelessInfo';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        addContainer: {
            display: 'flex',
            justifyContent: 'center',
            background: '#FFFFFF',
            borderRadius: '16px',
            margin: '20px',
            padding: '32px',
            width: '640px',
            '& .card': {
                width: '100%'
            }
        },
        btn: {
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            padding: '10px 20px',
            textTransform: 'none',
            width: '100%'
        },
        btnContainer: {
            position: 'relative',
            paddingBottom: '20px',
            marginBottom: '24px',
            zIndex: 5
        },
        modal: {
            position: 'fixed',
            left: '50%',
            top: '50%',
            zIndex: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            overflowY: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '100%'
        },
        modalContent: {
            position: 'relative',
            top: '5%',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            boxSizing: 'border-box',
            maxWidth: '660px',
            padding: '48px 0',
            width: '80%'
        },
        modalTitle: {
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            marginTop: '24px',
            padding: '30px 0 10px 0'
        }
    })
);

type CompleteMemberProfileProps = {
    handleModalClick: (open: boolean) => void;
    holder: Homeless;
    maxBonusAmount: number;
    profileItems: ProfileItems;
    remainingBonusAmount: number;
    supplementalQuestions: TouchpointQuestions[];
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
    updateHomelessInfoField: (field: string, value: string) => void;
    updateProfileCompletedItems: (type: string) => void;
    user: User;
};

const CompleteMemberProfile: React.FC<CompleteMemberProfileProps> = ({
    handleModalClick,
    holder,
    maxBonusAmount,
    profileItems,
    remainingBonusAmount,
    supplementalQuestions,
    touchpointAnswers,
    touchpointQuestions,
    updateHomelessInfoField,
    updateProfileCompletedItems,
    user
}) => {
    const classes = useStyles();

    const { action_step_bonus_monthly_total, id, mobile_photo, name } = holder;
    const { updateHomelessInfoRequest } = useUpdateHomelessInfo();

    const [dateISO, setDateISO] = useState<string>('');
    const [profilePicture, setProfilePicture] = useState<null | Blob>(null);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [type, setType] = useState<null | string>(null);

    const handleUpdateProfilePicture = useCallback(() => {
        const _formData = new FormData();

        if (profilePicture) {
            _formData.append('samaritan_member[photo]', profilePicture);

            updateHomelessInfoField(
                'photo',
                URL.createObjectURL(profilePicture)
            );
            updateProfileCompletedItems('photo');

            updateHomelessInfoRequest(id, _formData);
        }
    }, [
        id,
        profilePicture,
        updateHomelessInfoField,
        updateHomelessInfoRequest,
        updateProfileCompletedItems
    ]);

    const handleUpdateModalClose = () => {
        if (type) {
            setType(null);
        } else {
            handleModalClick(false);
        }
    };

    const renderComponents = () => {
        switch (type) {
            case 'action_steps':
                return (
                    <div className={classes.addContainer}>
                        <Add type="action step" {...addProps} />
                    </div>
                );
            case 'needs':
                return <Add type="need" {...addProps} />;
            case 'photo':
                return (
                    <PhotoPopup
                        handleModalClick={() => setType(null)}
                        setProfilePicture={setProfilePicture}
                    />
                );
            case 'status':
                return (
                    <UpdateStatus
                        answers={touchpointAnswers}
                        id={id}
                        name={name}
                        setUpdateStatus={() => setType(null)}
                        statusId={0}
                        questions={touchpointQuestions}
                    />
                );
            case 'supplemental_questions':
                return (
                    <PopUp
                        content="supplemental question"
                        answer={''}
                        handleModalClick={() => setType(null)}
                        id={id}
                        profileItemType={type}
                        prompt={supplementalQuestions[questionIndex].question}
                        questionID={supplementalQuestions[questionIndex].id}
                        questionIndex={questionIndex}
                        questionsLength={supplementalQuestions.length}
                        setQuestionIndex={setQuestionIndex}
                    />
                );
            default:
                return (
                    <AddInfo
                        id={id}
                        setType={setType}
                        type={type || ''}
                        updateHomelessInfoField={updateHomelessInfoField}
                        updateProfileCompletedItems={
                            updateProfileCompletedItems
                        }
                    />
                );
        }
    };

    const renderTitle = () => {
        switch (type) {
            case 'action_steps':
                return 'Action Steps';
            case 'birthday':
                return 'Date of Birth';
            case 'contact_info':
                return 'Contact Information';
            case 'full_name':
                return 'Legal Name';
            case 'member_id':
                return 'Insurance ID';
            case 'name':
                return 'Nickname';
            case 'needs':
                return 'Immediate Needs';
            case 'photo':
                return 'Profile Photo';
            case 'status':
                return 'Status';
            case 'supplemental_questions':
                return 'Fun Facts';
            default:
                return '';
        }
    };

    const addProps = {
        cancelAction: () => setType(null),
        dateISO,
        holder,
        maxBonusAmount,
        profileCompletionType: type,
        remainingBonusAmount,
        setDateISO,
        totalBonusAmount: action_step_bonus_monthly_total,
        updateProfileCompletedItems,
        userID: user.id
    };

    useEffect(() => {
        if (profilePicture) {
            handleUpdateProfilePicture();
        }
    }, [handleUpdateProfilePicture, profilePicture]);

    return (
        <Modal2
            onClose={handleUpdateModalClose}
            title="Complete Member Profile"
            modalTitle={type ? `Updating ${renderTitle()}` : ``}
        >
            {!type ? (
                <DefaultBody
                    name={name}
                    photo={mobile_photo}
                    profileItems={profileItems}
                    setType={setType}
                />
            ) : (
                renderComponents()
            )}
        </Modal2>
    );
};

export default CompleteMemberProfile;
