import { useCallback, useEffect, useRef, useState } from 'react';

import { EditButton } from '../buttons';
import FormInput from '../../../../_shared/FormInput';
import Underline from './Underline';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        editIcon: {
            height: 'inherit',
            width: 'inherit'
        },
        editIconContainer: {
            cursor: 'pointer',
            height: 'auto',
            margin: '0 0 0 15px',
            width: '1.5rem'
        },
        editUnderline: {
            backgroundColor: '#E3E4FA',
            border: '3px solid #E3E4FA',
            borderRadius: '10px',
            height: '0'
        },
        inputContainer: {
            marginBottom: '40px'
        },
        inputFields: {
            background: 'transparent',
            border: '0',
            borderRadius: '6px',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            padding: '10px 0px 0px 0px',
            resize: 'none',
            width: '100%',
            '&:focus': {
                outline: 'none !important'
            },
            '&:placeholder-shown': {
                fontStyle: 'italic'
            }
        },
        optionalLabel: {
            color: '#7A4406',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        requiredLabel: {
            color: '#A80A2D',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        underline: {
            backgroundColor: '#E3E4FA',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            height: '0',
            marginBottom: '20px'
        }
    })
);

type InputProps = {
    inputTitleClass: (input: string) => string;
    intakeInput: string;
    remoteCopy: {
        label: string;
        placeholder: string;
        required: boolean;
    };
    setIntakeInput: (input: string) => void;
    validateFn?: (input: string) => boolean;
};

const Input: React.FC<InputProps> = ({
    inputTitleClass,
    intakeInput,
    remoteCopy,
    setIntakeInput,
    validateFn
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { label, placeholder, required } = remoteCopy;

    const [input, setInput] = useState(intakeInput);
    const [savedInput, setSavedInput] = useState(
        intakeInput ? intakeInput.length > 0 : false
    );
    const [shouldFocus, setShouldFocus] = useState(false);

    const renderDisabledUnderline = () =>
        validateFn && !validateFn(input) && input && input.length > 0;

    const handleBlurClick = () => {
        if (input && input.length > 0) {
            handleSaveClick();
        }
    };

    const handleEditClick = () => {
        setSavedInput(false);
        setShouldFocus(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        setInput(value);
    };

    const handleSaveClick = useCallback(() => {
        if ((validateFn && !validateFn(input)) || input.length === 0) {
            return;
        }

        setSavedInput(true);

        if (!validateFn || (validateFn && validateFn(input))) {
            setIntakeInput(input);
        }
    }, [input, setIntakeInput, validateFn]);

    useEffect(() => {
        if (shouldFocus && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(input.length, input.length);
            setShouldFocus(false);
        }
    }, [input, shouldFocus, validateFn]);

    useEffect(() => {
        if (intakeInput) {
            setSavedInput(true);
        }
    }, [intakeInput]);

    // useEffect(() => {
    //     if (intakeInput && intakeInput.length > 0 && input.length === 0) {
    //         setIntakeInput('');
    //         setSavedInput(false);
    //     }
    // }, [input, intakeInput, setIntakeInput]);

    return (
        <div className={classes.inputContainer}>
            <span className={inputTitleClass(intakeInput)}>
                {label}
                <span
                    className={
                        classes[required ? 'requiredLabel' : 'optionalLabel']
                    }
                >
                    {`(${required ? 'REQUIRED' : 'OPTIONAL'})`}
                </span>
            </span>
            {!savedInput ? (
                <FormInput
                    autoComplete="on"
                    className={classes.inputFields}
                    id={`input-${label}`}
                    name={label}
                    onBlur={handleBlurClick}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    ref={inputRef}
                    saveInput={handleSaveClick}
                    value={input}
                />
            ) : (
                <EditButton
                    handleEditClick={handleEditClick}
                    inputValState={intakeInput}
                    saveInputState={savedInput}
                />
            )}
            {!savedInput && <Underline disabled={renderDisabledUnderline()} />}
        </div>
    );
};

export default Input;
