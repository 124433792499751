import AssignBody from './components/AssignBody';
import Modal2 from 'components/_shared/Modal2';

import { DebitCardOptions } from 'types';

type AssignCardPopUpProps = {
    debitCardOptions: DebitCardOptions[];
    handleModalClick: () => void;
    photo: string;
    setAssignPopUp?: (assignView: boolean) => void;
    setDebitType: (type: 'givecard' | 'pex' | null) => void;
    setReplaceView?: (replaceView: boolean) => void;
};

const AssignCardPopUp: React.FC<AssignCardPopUpProps> = ({
    debitCardOptions,
    handleModalClick,
    photo,
    setAssignPopUp,
    setDebitType,
    setReplaceView
}) => {
    const handleUpdateModalClose = () => {
        handleModalClick();
    };

    return (
        <Modal2 onClose={handleUpdateModalClose} title="Assign Card">
            <AssignBody
                debitCardOptions={debitCardOptions}
                photo={photo}
                setAssignPopUp={setAssignPopUp}
                setDebitType={setDebitType}
                setReplaceView={setReplaceView}
            />
        </Modal2>
    );
};

export default AssignCardPopUp;
