import { useState } from 'react';

import { DeploymentUsers } from 'types';

import Card from 'components/_shared/Card';
import { CTAButton1 } from 'components/_shared/buttons';
import ManagerItem from './ManagerItem';
import ManagerSearch from './ManagerSearch';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 20px !important',
            position: 'relative',
            height: '90%',
            width: '90%'
        },
        label: {
            position: 'absolute',
            left: 0,
            top: '32px',
            background: '#E7EFFE',
            borderRadius: '0 6px 6px 0',
            color: '#062D7A',
            fontWeight: 600,
            padding: '4px 12px',
            '& i': {
                marginRight: '8px'
            }
        },
        managerList: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
            margin: '16px 0 32px 0',
            maxHeight: '60%',
            overflow: 'auto',
            paddingRight: '16px',
            width: '100%'
        },
        managerPopupContainer: {
            position: 'absolute',
            top: '0',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%'
        },
        referButton: {
            fontSize: '16px',
            fontWeight: 700,
            width: '100%'
        },
        referButtonContainer: {
            marginTop: 'auto',
            width: '100%'
        }
    })
);

type ManagerListProps = {
    deploymentUsers: DeploymentUsers[];
    orgIDs: number;
    referralManager: DeploymentUsers | null;
    setManagerPopup: (open: boolean) => void;
    setReferralManager: (manager: DeploymentUsers | null) => void;
};

const ManagerList: React.FC<ManagerListProps> = ({
    deploymentUsers,
    orgIDs,
    referralManager,
    setManagerPopup,
    setReferralManager
}) => {
    const classes = useStyles();

    const [state, setState] = useState({
        query: ''
    });

    const { query } = state;

    const filteredUsers = deploymentUsers
        ? deploymentUsers.filter(
              (user) =>
                  user.name.toLowerCase().includes(query.toLowerCase()) &&
                  orgIDs === user.organization_id
          )
        : [];

    const setQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setState({ query: value });
    };

    return (
        <div className={classes.managerPopupContainer}>
            <Card className={classes.card}>
                <span className={classes.label}>
                    <i className="fas fa-user" />
                    Specifying a case manager:
                </span>
                <ManagerSearch
                    query={query}
                    setQuery={setQuery}
                    setState={setState}
                />
                <div className={classes.managerList}>
                    {filteredUsers.length
                        ? filteredUsers.map((user, index) => (
                              <ManagerItem
                                  key={index}
                                  manager={user}
                                  referralManager={referralManager}
                                  setReferralManager={setReferralManager}
                              />
                          ))
                        : 'No case manager(s) found!'}
                </div>
                <div className={classes.referButtonContainer}>
                    <CTAButton1
                        className={classes.referButton}
                        disabled={!referralManager}
                        onClick={() => setManagerPopup(false)}
                    >
                        {referralManager
                            ? `Refer to ${referralManager.name}`
                            : 'Select a case manager'}
                    </CTAButton1>
                </div>
            </Card>
        </div>
    );
};

export default ManagerList;
