import { useCallback, useEffect, useRef, useState } from 'react';

import Underline from './Underline';
import { EditButton } from '../buttons';

import { useDebounce } from 'hooks';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        datePicker: {
            color: '#1f1f51',
            width: '100%',
            '& .MuiInputBase-root': {
                color: '#1f1f51',
                opacity: '0.6',
                fontFamily: 'Manrope',
                fontSize: '1.75rem',
                fontStyle: 'italic',
                '& input': {
                    padding: '10px 0 0 0px'
                }
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
        inputContainer: {
            marginBottom: '40px'
        },
        inputFields: {
            background: 'transparent',
            border: '0',
            borderRadius: '6px',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            padding: '10px 0px 0px 0px',
            resize: 'none',
            width: '100%',
            '&:focus': {
                outline: 'none !important'
            },
            '&::placeholder': {
                fontStyle: 'italic',
                opacity: '0.6',
                color: '#1F1F51'
            }
        },
        optionalLabel: {
            color: '#7A4406',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        requiredLabel: {
            color: '#A80A2D',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        underline: {
            backgroundColor: '#E3E4FA',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            height: '0',
            marginBottom: '20px'
        }
    })
);

// const formatLongDate = (dateInput: Date | null) => {
//     if (!dateInput) return '';

//     const date = new Date(dateInput);
//     const options: Intl.DateTimeFormatOptions = {
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric'
//     };
//     return date.toLocaleDateString('en-US', options);
// };

const formatShortDate = (dateInput: Date | null) => {
    if (!dateInput) return '';

    const date = new Date(dateInput);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

type BirthdayInputProps = {
    inputTitleClass: (input: string) => string;
    intakeInput: string;
    remoteCopy: {
        label: string;
        placeholder: string;
        required: boolean;
    };
    setIntakeInput: (input: string) => void;
};

const BirthdayInput: React.FC<BirthdayInputProps> = ({
    inputTitleClass,
    intakeInput,
    remoteCopy,
    setIntakeInput
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLDivElement>(null);

    const { label, required } = remoteCopy;

    const [date, setDate] = useState<Date | null>(null);
    const [error, setError] = useState<null | string>(null);
    const [input, setInput] = useState<string>(intakeInput);
    const [open, setOpen] = useState<boolean>(false);
    const [savedInput, setSavedInput] = useState<boolean>(
        intakeInput ? intakeInput.length > 0 : false
    );

    const debouncedDate = useDebounce(formatShortDate(date), 400);
    const debounceInput = useDebounce(input, 200);

    const handleEditClick = () => {
        setOpen(true);
        setSavedInput(false);
    };

    const handleInputChange = (value: Date | null) => {
        setDate(value);
    };

    const handleInputClick = () => {
        if (inputRef.current) {
            setSavedInput(false);
        }

        setOpen(true);
    };

    const handleSaveClick = useCallback(() => {
        setSavedInput(true);

        if (input && input.length > 0 && input !== intakeInput) {
            setIntakeInput(input);
        }
    }, [input, intakeInput, setIntakeInput]);

    useEffect(() => {
        if (
            debounceInput &&
            debounceInput.length === 10 &&
            !error &&
            intakeInput !== input
        ) {
            handleSaveClick();
        }
    }, [debounceInput, error, handleSaveClick, input, intakeInput]);

    useEffect(() => {
        setInput(debouncedDate);
    }, [debouncedDate]);

    useEffect(() => {
        if (intakeInput && intakeInput.length > 0) {
            setSavedInput(true);
        }
    }, [intakeInput]);

    return (
        <div className={classes.inputContainer}>
            <span className={inputTitleClass(intakeInput)}>
                {label}
                <span
                    className={
                        classes[required ? 'requiredLabel' : 'optionalLabel']
                    }
                >
                    {`(${required ? 'REQUIRED' : 'OPTIONAL'})`}
                </span>
            </span>
            {!savedInput ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        className={classes.datePicker}
                        disableFuture
                        format="MM-dd-yyyy"
                        onChange={(value: Date | null) =>
                            handleInputChange(value)
                        }
                        onError={(newError) => setError(newError)}
                        onOpen={handleInputClick}
                        open={open}
                        ref={inputRef}
                        referenceDate={new Date('2000-01-02')}
                        slotProps={{
                            textField: { placeholder: 'January 1, 2000' }
                        }}
                        value={date}
                    />
                </LocalizationProvider>
            ) : (
                <EditButton
                    date
                    handleEditClick={handleEditClick}
                    inputValState={intakeInput}
                    saveInputState={savedInput}
                />
            )}
            {!savedInput && <Underline disabled={input.length < 0 || error} />}
        </div>
    );
};

export default BirthdayInput;
