import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

import { Caseload, Homeless, User } from 'types';

import EmptyList from './components/EmptyList';
import FAQButton from './components/FAQButton';
import ListHeader from './components/ListHeader';
import ListItem from './components/MemberCard';
import LoadingCircle from '../_shared/LoadingCircle';
import Nav from '../_shared/NavSelector';
import PopupReferral from './components/ReferralComponents/PopupReferral';
import SearchBar from './components/SearchBar';

import useDebounce from 'hooks/useDebounce';
import { isObjectEmpty } from 'util/index';

import { Box, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            overflow: 'auto',
            width: '100%'
        },
        empty: {
            fontSize: '1.2rem',
            fontWeight: 600,
            marginLeft: '16px'
        },
        holderList: {
            margin: '16px 0',
            width: '96%'
        },
        navContainer: {
            width: '95%'
        },
        skeletonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            justifyContent: 'center',
            width: '98%'
        }
    })
);

type HoldersListProps = {
    caseloads: Caseload[];
    deploymentUsers: any;
    getCaseloads: () => void;
    getDeploymentOrgs: (id: number) => void;
    getDeploymentUsers: () => void;
    getHomelesses: () => void;
    homelesses: Homeless[];
    isFetchingCaseloads: boolean;
    isFetchingDeploymentUsers: boolean;
    isFetchingHomelesses: boolean;
    layoutTitles: {
        subtitle: string;
        title: string;
    };
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    resetCatchUpItemStatus: () => void;
    resetHomelessInfo: () => void;
    resetMemberReferrals: () => void;
    resetMessageInfo: () => void;
    resetTouchpointAnswers: () => void;
    resetUnenrollmentState: () => void;
    saveMemberToMyMembers: (caseloadID: number, memberID: number) => void;
    setLayoutTitles: (title: string, subtitle: string) => void;
    user: User;
};

const HoldersList: React.FC<HoldersListProps> = memo(
    ({
        caseloads,
        deploymentUsers,
        getCaseloads,
        getDeploymentUsers,
        getHomelesses,
        homelesses,
        isFetchingCaseloads,
        isFetchingDeploymentUsers,
        isFetchingHomelesses,
        layoutTitles,
        removeMemberFromMyMembers,
        resetCatchUpItemStatus,
        resetHomelessInfo,
        resetMemberReferrals,
        resetMessageInfo,
        resetTouchpointAnswers,
        resetUnenrollmentState,
        saveMemberToMyMembers,
        setLayoutTitles,
        user
    }) => {
        const classes = useStyles();

        ReactGA.send({ hitType: 'pageview', page: '/holders' });

        const { is_non_profit, partner } = user;
        const {
            deployment,
            deployments,
            deployment_ids,
            id: user_org_id,
            name: org_name
        } = partner || '';

        const [active, setActive] = useState('My Members');
        const [userOrgID, setUserOrgID] = useState(user_org_id);
        const [referCaseload, setReferCaseload] = useState<Caseload | null>(
            null
        );
        const [referHolder, setReferHolder] = useState<Homeless | null>(null);
        const [referPopup, setReferPopup] = useState(false);
        const [state, setState] = useState({
            query: ''
        });

        const { query } = state;
        const debouncedQuery = useDebounce(query, 300);

        const empty = homelesses && homelesses.length === 0;
        const isLoading =
            isFetchingCaseloads ||
            isFetchingDeploymentUsers ||
            isFetchingHomelesses;
        const isSmall = useMediaQuery('(max-width: 1278px)');

        const deploymentNames = useMemo(
            () =>
                (deployments &&
                    deployments.map((d) => d.name + `'s Members`)) ||
                [],
            [deployments]
        );

        const filterMembers = useCallback(
            (
                members: Homeless[],
                filterFunc: (item: Homeless) => boolean
            ): Homeless[] => {
                return members && members.length > 0
                    ? members
                          .filter((item) => filterFunc(item))
                          .sort((a, b) => {
                              // Move items with profile_stage 'PENDING' to the top
                              if (
                                  a.profile_stage === 'PENDING' &&
                                  b.profile_stage !== 'PENDING'
                              )
                                  return -1;
                              if (
                                  a.profile_stage !== 'PENDING' &&
                                  b.profile_stage === 'PENDING'
                              )
                                  return 1;
                              return 0;
                          })
                    : [];
            },
            []
        );

        const filteredCaseloadMembers = useMemo(() => {
            const filteredMembers = (
                members: Homeless[],
                caseloads: Caseload[],
                user_org_id?: number,
                deployment_id?: number
            ) => {
                const filteredMembers = filterMembers(
                    members,
                    (member) =>
                        member.profile_stage !== 'EXITED' &&
                        member.profile_stage !== 'UNSUCCESSFUL_PENDING' &&
                        (caseloads || []).some(
                            (caseload) =>
                                caseload.homeless_id === member.id &&
                                (caseload.status === 'active' ||
                                    caseload.status === 'pending' ||
                                    (caseload.status === 'cancelled' &&
                                        caseload.is_accepted &&
                                        caseload.is_accepted ===
                                            'referral rejected'))
                        ) &&
                        (user_org_id
                            ? member.organization_id === user_org_id
                            : true) &&
                        (deployment_id
                            ? member.deployment_id === deployment_id
                            : true)
                );

                // Sort filteredMembers array by caseload status, pending first
                filteredMembers.sort((a, b) => {
                    const statusOrder = {
                        pending: 0,
                        'referral accepted': 1,
                        'referral rejected': 2,
                        active: 3,
                        '': 99
                    } as { [key: string]: number };

                    const getProfileStageOrder = (member: Homeless) =>
                        member.profile_stage === 'PENDING' ||
                        member.profile_stage === 'MEMBERSHIP_CONFIRMED' ||
                        member.profile_stage === 'UNSUCCESSFUL_PENDING'
                            ? -1
                            : 1;

                    const profileStageOrder =
                        getProfileStageOrder(a) - getProfileStageOrder(b);

                    if (profileStageOrder !== 0) {
                        return profileStageOrder;
                    }

                    const getStatusOrder = (memberId: number) => {
                        const caseload = caseloads.find(
                            (caseload) => caseload.homeless_id === memberId
                        );

                        return caseload
                            ? statusOrder[
                                  caseload.is_accepted
                                      ? caseload.is_accepted
                                      : caseload.status.toLowerCase()
                              ]
                            : statusOrder[''];
                    };

                    const aStatusOrder = getStatusOrder(a.id);
                    const bStatusOrder = getStatusOrder(b.id);

                    return aStatusOrder - bStatusOrder;
                });

                return filteredMembers;
            };

            return filteredMembers;
        }, [filterMembers]);

        const renderMembers = useCallback(
            (memberList: Homeless[]) => {
                if (memberList === null) {
                    return <LoadingCircle />;
                }

                if (memberList !== null && memberList.length === 0 && !empty) {
                    return (
                        <p className={classes.empty}>
                            No Members match your search
                        </p>
                    );
                }

                if (memberList !== null && memberList.length > 0) {
                    return memberList.map((h) => {
                        const memberCaseload = caseloads?.filter(
                            (caseload) => caseload.homeless_id === h.id
                        )[0];

                        return (
                            <Grid
                                key={`member-${h.id}`}
                                xs={12}
                                sm={6}
                                lg={4}
                                sx={{
                                    minHeight: '240px',
                                    maxHeight: '240px',
                                    padding: isSmall
                                        ? '8px !important'
                                        : '20px !important'
                                }}
                                item
                            >
                                <ListItem
                                    caseload={memberCaseload}
                                    holder={h}
                                    setReferCaseload={setReferCaseload}
                                    setReferHolder={setReferHolder}
                                    setReferPopup={setReferPopup}
                                />
                            </Grid>
                        );
                    });
                }
            },
            [classes.empty, caseloads, empty, isSmall]
        );

        const SkeletonLayout = () => {
            return (
                <>
                    <Box sx={{ padding: 2, width: '96%' }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Skeleton
                                    variant="rounded"
                                    height={64}
                                    animation="wave"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Skeleton
                                    variant="rounded"
                                    height={48}
                                    width="100%"
                                    animation="wave"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Skeleton
                                    variant="rounded"
                                    height={48}
                                    width="100%"
                                    animation="wave"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Skeleton
                                    variant="rounded"
                                    height={48}
                                    width="100%"
                                    animation="wave"
                                />
                            </Grid>
                            {[...Array(9)].map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Skeleton
                                        variant="rounded"
                                        height={240}
                                        animation="wave"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </>
            );
        };

        const searchMembersByQuery = useMemo(() => {
            let filteredMembers = homelesses;

            if (debouncedQuery.length > 0) {
                let queryCharacters = debouncedQuery
                    .split('')
                    .map((c) => {
                        return c === ' '
                            ? '\\s'
                            : `[${c.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}]`;
                    })
                    .join('(\\w|\\s)*');

                filteredMembers = homelesses.filter((h) => {
                    const nameRegex = new RegExp(queryCharacters, 'gi');
                    const fullName =
                        `${h.first_name || ''} ${h.last_name || ''}`.trim();

                    return (
                        h.deployment_id &&
                        deployment_ids?.includes(h.deployment_id) &&
                        (h.name?.match(nameRegex) ||
                            h.first_name?.match(nameRegex) ||
                            h.last_name?.match(nameRegex) ||
                            fullName.match(nameRegex))
                    );
                });
            }

            return renderMembers(filteredMembers);
        }, [deployment_ids, homelesses, debouncedQuery, renderMembers]);

        const setQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            setState({ query: value });
        };

        const myMembers = filteredCaseloadMembers(homelesses, caseloads);

        const depMembers = (id: number) =>
            filterMembers(
                homelesses,
                (item) =>
                    item.deployment_id === id &&
                    item.profile_stage !== 'EXITED' &&
                    item.profile_stage !== 'UNSUCCESSFUL_PENDING' &&
                    !myMembers.some((m) => m.id === item.id)
            );

        const exitedMembers = !isObjectEmpty(homelesses)
            ? [
                  ...homelesses.filter(
                      (item) => item.profile_stage === 'UNSUCCESSFUL_PENDING'
                  ),
                  ...homelesses.filter(
                      (item) => item.profile_stage === 'EXITED'
                  )
              ]
            : [];

        const orgMembers = filterMembers(
            homelesses,
            (item) =>
                item.organization_id === user_org_id &&
                item.profile_stage !== 'EXITED' &&
                item.profile_stage !== 'UNSUCCESSFUL_PENDING' &&
                !myMembers.some((m) => m.id === item.id)
        );

        const pendingMembers = filterMembers(
            homelesses,
            (item) => item.profile_stage === 'PENDING'
        );

        const navItems = useMemo(() => {
            return [
                myMembers.length || empty ? 'My Members' : null,
                (org_name && orgMembers.length) || empty
                    ? `${org_name} Members`
                    : null,
                ...(deployment?.organization_ids.length > 1
                    ? deploymentNames
                    : []),
                pendingMembers.length || empty ? 'Pending Members' : null,
                exitedMembers.length || empty ? `Unenrolled Members` : null
            ];
        }, [
            deployment,
            deploymentNames,
            empty,
            exitedMembers.length,
            myMembers.length,
            orgMembers.length,
            org_name,
            pendingMembers.length
        ]);

        useEffect(() => {
            if (user.partner && user.is_non_profit && !userOrgID) {
                setUserOrgID(user.partner.id);
            } else {
                setUserOrgID(user_org_id);
            }
        }, [user, userOrgID, user_org_id]);

        useEffect(() => {
            if (layoutTitles.subtitle !== 'Client List') {
                setLayoutTitles('', 'Client List');
            }

            resetCatchUpItemStatus();
            resetHomelessInfo();
            resetMessageInfo();
            resetMemberReferrals();
            resetTouchpointAnswers();
            resetUnenrollmentState();
        }, [
            layoutTitles.subtitle,
            resetCatchUpItemStatus,
            resetHomelessInfo,
            resetMemberReferrals,
            resetMessageInfo,
            resetTouchpointAnswers,
            resetUnenrollmentState,
            setLayoutTitles
        ]);

        // // TODO: test this to see if this actually works
        // useEffect(() => {
        //     if (
        //         (history.location.state as { unenrollSuccess: boolean })
        //             ?.unenrollSuccess === true
        //     ) {
        //         getHomelesses();
        //     }
        // }, [getHomelesses, history.location]);

        useEffect(() => {
            if (!isFetchingCaseloads && caseloads === null && user?.id) {
                getCaseloads();
            }
        }, [caseloads, getCaseloads, isFetchingCaseloads, user?.id]);

        useEffect(() => {
            if (!isFetchingDeploymentUsers && !deploymentUsers && user?.id) {
                getDeploymentUsers();
            }
        }, [
            deploymentUsers,
            getDeploymentUsers,
            isFetchingDeploymentUsers,
            user?.id
        ]);

        useEffect(() => {
            if (
                !isFetchingHomelesses &&
                isObjectEmpty(homelesses) &&
                userOrgID &&
                is_non_profit
            ) {
                getHomelesses();
            }
        }, [
            getHomelesses,
            homelesses,
            isFetchingHomelesses,
            is_non_profit,
            userOrgID
        ]);

        useEffect(() => {
            const updateMembers = async () => getHomelesses();

            if (
                layoutTitles.subtitle !== 'Client List' &&
                layoutTitles.subtitle !== 'Member Intake' &&
                layoutTitles.subtitle !== 'Settings' &&
                layoutTitles.subtitle! === 'Your Referrals' &&
                !isObjectEmpty(homelesses)
            ) {
                const memberHandle = layoutTitles.subtitle
                    .split('. ')[1]
                    .replace(/[()]/g, '');

                if (!isObjectEmpty(homelesses)) {
                    const member = homelesses.find(
                        (h) => h.handle === memberHandle
                    );

                    if (!member) {
                        updateMembers();
                    }
                }
            }
        }, [getHomelesses, homelesses, layoutTitles.subtitle, userOrgID]);

        useEffect(() => {
            const firstNavItem = navItems.find((item) => item !== null);

            if (firstNavItem) {
                setActive(firstNavItem);
            }
        }, [navItems]);

        return (
            <div className={classes.root}>
                <Helmet title="Client List" />
                <ListHeader />
                {isLoading ? (
                    <SkeletonLayout />
                ) : (
                    <>
                        <SearchBar
                            empty={empty}
                            query={query}
                            setQuery={setQuery}
                            setState={setState}
                        />
                        <div className={classes.navContainer}>
                            {!debouncedQuery.length ? (
                                <Nav
                                    active={active}
                                    setActive={setActive}
                                    empty={empty}
                                    navItems={navItems}
                                    style={{
                                        justifyContent: 'flex-start',
                                        padding: '20px 0',
                                        margin: '0 8px'
                                    }}
                                />
                            ) : (
                                <h2>Results:</h2>
                            )}
                        </div>
                        <Grid container className={classes.holderList}>
                            {empty && <EmptyList />}
                            {debouncedQuery.length > 0 ? (
                                <>{searchMembersByQuery}</>
                            ) : (
                                <>
                                    {myMembers &&
                                        active === 'My Members' &&
                                        renderMembers(myMembers)}
                                    {orgMembers &&
                                        active === `${org_name} Members` &&
                                        renderMembers(orgMembers)}
                                    {depMembers &&
                                        deployments &&
                                        deployments.map(
                                            (deployment) =>
                                                active ===
                                                    `${deployment.name}'s Members` &&
                                                renderMembers(
                                                    depMembers(deployment.id)
                                                )
                                        )}
                                    {exitedMembers &&
                                        active === 'Unenrolled Members' &&
                                        renderMembers(exitedMembers)}
                                    {pendingMembers &&
                                        active === 'Pending Members' &&
                                        renderMembers(pendingMembers)}
                                </>
                            )}
                            <FAQButton
                                isFetchingHomelesses={isFetchingHomelesses}
                            />
                        </Grid>
                    </>
                )}
                {referPopup && (
                    <PopupReferral
                        handleCloseClick={() => setReferPopup(false)}
                        referCaseload={referCaseload!}
                        referHolder={referHolder!}
                        removeMemberFromMyMembers={removeMemberFromMyMembers}
                        saveMemberToMyMembers={saveMemberToMyMembers}
                    />
                )}
            </div>
        );
    }
);

export default HoldersList;

// const filterMembers2 = useCallback(() => {
//     const newExistedMembers: Homeless[] = [];
//     const newMyMembers: Homeless[] = [];
//     const newOrgMembers: Homeless[] = [];
//     const newDeploymentMembers: {
//         deployment_id: number | null;
//         members: Homeless[];
//     }[] = [];

//     if (!isObjectEmpty(homelesses)) {
//         homelesses.forEach((member) => {
//             if (member.profile_stage === 'EXITED') {
//                 newExistedMembers.push(member);
//             } else if (
//                 caseloads.some(
//                     (caseload) =>
//                         caseload.homeless_id === member.id &&
//                         caseload.status === 'active'
//                 )
//             ) {
//                 newMyMembers.push(member);
//             } else if (member.organization_id === user_org_id) {
//                 newOrgMembers.push(member);
//             } else {
//                 const deploymentIndex = newDeploymentMembers.findIndex(
//                     (deployment) =>
//                         deployment.deployment_id === member.deployment_id
//                 );

//                 if (deploymentIndex === -1) {
//                     newDeploymentMembers.push({
//                         deployment_id: member.deployment_id,
//                         members: [member]
//                     });
//                 } else {
//                     newDeploymentMembers[deploymentIndex].members.push(
//                         member
//                     );
//                 }
//             }
//         });
//     }

//     console.log('newExistedMembers', newExistedMembers);
//     console.log('newMyMembers', newMyMembers);
//     console.log('newOrgMembers', newOrgMembers);
//     console.log('newDeploymentMembers', newDeploymentMembers);
// }, [caseloads, homelesses, user_org_id]);
