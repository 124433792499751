import { useState } from 'react';
import Lottie from 'lottie-react';

import Sparkle from 'assets/lottie/sparkle-effect.json';

const ActionStepSparkle = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [loopCount, setLoopCount] = useState(0);

    const handleLoopComplete = () => {
        if (loopCount < 2) {
            setLoopCount(loopCount + 1);
        } else {
            setIsVisible(false);
        }
    };

    return (
        isVisible && (
            <Lottie
                animationData={Sparkle}
                onLoopComplete={handleLoopComplete}
                loop={3}
                style={{
                    position: 'absolute',
                    zIndex: 1,
                    height: '56px',
                    right: '-40px',
                    top: '-32px',
                    width: '56px'
                }}
            />
        )
    );
};

export default ActionStepSparkle;
