import AuthManager from '../auth';

import { SAMARITAN_URL } from '../settings';

export async function fetchOrganization(orgId: number) {
    const token = AuthManager.getInstance().getToken();

    let base_url = `${SAMARITAN_URL}/api/v3/organizations/${orgId}`;
    let params = `?SESSION_TOKEN=${token}`;
    let url = `${base_url}${params}`;

    return fetch(url).catch((error) => {
        console.log('Fetching organization error:', error);
        throw error;
    });
}

export async function fetchAllOrganizations() {
    let base_url = `${SAMARITAN_URL}/api/v3/organizations`;

    return fetch(base_url).catch((error) => {
        console.log('Fetching all organizations error:', error);
        throw error;
    });
}

export async function fetchOrganizationsByCity(city: string) {
    if (!city || typeof city !== 'string') {
        console.error('Invalid city parameter:', city);
        throw new TypeError('City must be a non-empty string');
    }

    const base_url = `${SAMARITAN_URL}/api/v3/organizations`;
    const params = `?subdomain=${encodeURIComponent(city)}`;
    const url = `${base_url}${params}`;

    try {
        const response = await fetch(url, {
            headers: {
                accept: 'application/json'
            }
        });

        if (!response.ok) {
            console.error(`Failed to fetch organizations for city: ${city}`, {
                status: response.status,
                statusText: response.statusText
            });

            throw new Error(
                `Failed to fetch organizations: ${response.statusText}`
            );
        }

        return await response.json();
    } catch (error) {
        console.error('Fetching organizations by city error:', { city, error });
        throw error;
    }
}
