import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Dialog } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            padding: '10px 20px',
            textTransform: 'none',
            width: '100%'
        },
        btnContainer: {
            position: 'relative',
            zIndex: 5,
            padding: '16px 0'
        },
        modal: {
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            height: '100vh',
            overflowY: 'auto',
            width: '100%',
            '@media (min-width: 768px)': {
                paddingLeft: '150px'
            }
        },
        modalContent: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            boxSizing: 'border-box',
            marginBottom: '16px',
            width: '100%'
        },
        modalTitle: {
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            marginTop: '64px'
        }
    })
);

type ModalProps = {
    children: React.ReactNode;
    modalTitle?: string;
    onClose: () => void;
    open?: boolean;
    showCloseButton?: boolean;
    title: string;
    width?: string;
};

const Modal: React.FC<ModalProps> = ({
    children,
    modalTitle,
    onClose,
    open,
    showCloseButton,
    title,
    width = '660px'
}) => {
    const classes = useStyles();
    const contentRef = useRef<HTMLDivElement | null>(null);

    const [isOverflowing, setIsOverflowing] = useState(false);

    const checkOverflow = useCallback(() => {
        if (contentRef.current) {
            const modalContentHeight = contentRef.current.scrollHeight + 96;
            const viewportHeight = window.innerHeight;

            setIsOverflowing(modalContentHeight > viewportHeight);
        }
    }, []);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );

    const handleModalClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const setContentRef = useCallback(
        (node: HTMLDivElement) => {
            contentRef.current = node;

            checkOverflow();
        },
        [checkOverflow]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown, onClose]);

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            checkOverflow();
        });

        if (contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => {
            observer.disconnect();
        };
    });

    useEffect(() => {
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [checkOverflow, children]);

    return (
        <Dialog open={open || true} onClose={onClose}>
            <div
                className={classes.modal}
                onClick={handleModalClick}
                style={{
                    justifyContent: isOverflowing ? 'flex-start' : 'center'
                }}
            >
                {modalTitle && (
                    <span className={classes.modalTitle}>{modalTitle}</span>
                )}
                <div
                    className={classes.modalContent}
                    aria-labelledby={title}
                    ref={setContentRef}
                    role="dialog"
                    style={{
                        marginTop: modalTitle ? '0' : '64px',
                        maxWidth: width
                    }}
                >
                    {children}
                    {showCloseButton && (
                        <div className={classes.btnContainer}>
                            <Button className={classes.btn} onClick={onClose}>
                                Close
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default Modal;
