import { useState } from 'react';

import ReferBody from './components/ReferBody';
import Modal2 from 'components/_shared/Modal2';

import { DeploymentOrgs, DeploymentUsers, Homeless, User } from 'types';

type ReferMemberProps = {
    deploymentOrgs: DeploymentOrgs[];
    deploymentUsers: DeploymentUsers[];
    handleModalClick: (open: boolean) => void;
    holder: Homeless;
    isReferringMember: boolean;
    referMember: () => Promise<void>;
    referMemberError: boolean | null;
    setReferMemberPopUp: (value: boolean) => void;
    user: User;
};

const ReferMember: React.FC<ReferMemberProps> = ({
    deploymentOrgs,
    deploymentUsers,
    handleModalClick,
    holder,
    isReferringMember,
    referMember,
    referMemberError,
    setReferMemberPopUp,
    user
}) => {
    const { id: memberID, mobile_photo, name } = holder;
    const { partner } = user;
    const deployments = partner?.deployments || [];

    const [managerPopup, setManagerPopup] = useState(false);
    const [note, setNote] = useState('');
    const [notePopUp, setNotePopUp] = useState(false);
    const [orgIDs, setOrgIDs] = useState<number>(0);
    const [referralManager, setReferralManager] =
        useState<DeploymentUsers | null>(null);
    const [referralOrg, setReferralOrg] = useState<DeploymentOrgs | null>(null);

    const handleCloseClick = () => {
        setReferralManager(null);
        setReferralOrg(null);
        handleModalClick(false);
    };

    const referBodyProps = {
        deployments,
        deploymentOrgs,
        deploymentUsers,
        isReferringMember,
        managerPopup,
        memberID,
        mobile_photo,
        name,
        note,
        notePopUp,
        orgIDs,
        referMember,
        referMemberError,
        referralManager,
        referralOrg,
        setManagerPopup,
        setNote,
        setNotePopUp,
        setOrgIDs,
        setReferMemberPopUp,
        setReferralManager,
        setReferralOrg
    };

    return (
        <Modal2 onClose={handleCloseClick} title="Refer Member">
            <ReferBody {...referBodyProps} />
        </Modal2>
    );
};

export default ReferMember;
