import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { getFAQs } from '../../../api/faq';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    faqBtn: {
        position: 'fixed',
        zIndex: 3,
        bottom: '30px',
        right: '30px',
        borderRadius: '50px',
        boxShadow: '0px 8px 16px 4px rgba(0,0,0,0.15)',
        cursor: 'pointer',
        fontSize: '24px',
        height: '64px',
        lineHeight: '24px',
        width: '64px',
        '& i': {
            height: '24px',
            width: '18px'
        }
    },
    faqBtnClose: {
        background: '#7279E8',
        color: '#FFFFFF',
        '&:hover': {
            background: '#918ed7'
        }
    },
    faqBtnOpen: {
        background: '#FFFFFF',
        color: '#7378E8'
    },
    faqMenu: {
        position: 'fixed',
        zIndex: 2,
        bottom: '60px',
        right: '60px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 8px 16px 4px rgba(0,0,0,0.1)',
        padding: '16px 16px 24px',
        width: '232px',
        '& > div': {
            marginBottom: '16px',
            width: '100%',
            '& > h3': {
                color: '#000000',
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '24px'
            },
            '& > p': {
                color: '#000000',
                fontSize: '14px',
                lineHeight: '20px'
            }
        }
    },
    menuItemContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    menuItem: {
        color: '#1F2152',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        marginBottom: '16px',
        textDecoration: 'none'
    },
    divider: {
        background: '#E3E3FA',
        height: '1px',
        width: '100%'
    }
}));

const FAQButton = ({
    isFetchingHomelesses
}: {
    isFetchingHomelesses: boolean;
}) => {
    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);
    const [faq, setFaq] = useState<
        | {
              title: string;
              button_url_1: string;
          }[]
        | null
    >(null);
    const [showMenu, setShowMenu] = useState(false);

    const handleFaqClick = () => {
        ReactGA.event({
            category: 'FAQ',
            action: 'Click',
            label: showMenu ? 'Close FAQ' : 'Open FAQ'
        });

        if (showMenu) {
            setShowMenu(false);
        } else {
            setShowMenu(true);
        }
    };

    const renderBtnStyle = () => {
        if (showMenu) {
            return `${classes.faqBtn} ${classes.faqBtnClose}`;
        } else {
            return `${classes.faqBtn} ${classes.faqBtnOpen}`;
        }
    };

    useEffect(() => {
        let isMounted = true;

        const getFaq = async () => {
            try {
                const res = await getFAQs();
                const data = await res.json();

                if (isMounted && !isFetchingHomelesses) {
                    setFaq(data.sticky_tiles);
                }
            } catch (err) {
                console.log(err);
            }
        };

        getFaq();

        return () => {
            isMounted = false;
        };
    }, [isFetchingHomelesses]);

    useEffect(() => {
        if (faq && faq.length > 0) {
            setLoaded(true);
        }
    }, [faq]);

    return (
        <>
            {loaded ? (
                <div className="faqButton_component">
                    {showMenu && faq ? (
                        <div className={classes.faqMenu}>
                            {faq.map((item, index) => (
                                <div
                                    className={classes.menuItemContainer}
                                    style={
                                        index !== faq.length - 1
                                            ? { marginBottom: '16px' }
                                            : { marginBottom: '0' }
                                    }
                                    key={index}
                                >
                                    <a
                                        className={classes.menuItem}
                                        style={
                                            index === faq.length - 1
                                                ? { marginBottom: '0' }
                                                : { marginBottom: '16px' }
                                        }
                                        href={item.button_url_1}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.title}
                                    </a>
                                    {index !== faq.length - 1 ? (
                                        <div className={classes.divider} />
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <Button
                        className={renderBtnStyle()}
                        onClick={handleFaqClick}
                    >
                        {showMenu ? (
                            <i className="fas fa-times" />
                        ) : (
                            <i className="fas fa-question" />
                        )}
                    </Button>
                </div>
            ) : null}
        </>
    );
};

export default FAQButton;
