import * as error from './error';
import * as samaritan from './samaritan';
import * as catchUpItems from './catch_up_items';
import * as debitCard from './debit_card';
import * as deployments from './deployments';
import * as determinantRanks from './determinant_ranks';
import * as goalActions from './goal_actions';
import * as goals from './goals';
import * as homeless from './homeless';
import * as homelesses from './homelesses';
import * as intake from './intake';
import * as local from './local';
import * as messages from './messages';
import * as needs from './needs';
import * as newsfeed from './newsfeed';
import * as organization from './organization';
import * as payments from './payments';
import * as pex from './pex';
import * as referrals from './referrals';
import * as skills from './skills';
import * as touchpointAnswers from './touchpointAnswers';
import * as touchpointQuestions from './touchpointQuestions';
import * as touchpoints from './touchpoints';
import * as unenrollment from './unenrollment';
import * as user from './user';

export function handleSamaritanApiResponse(response: any, dispatch: Function) {
    if (response.ok || response.status === 400 || response.status === 403) {
        return response.json();
    } else if (response.status === 401) {
        dispatch(error.foundUnauthorizedUser());
        throw new Error(String(response.status));
    } else if (response.status === 404) {
        throw new Error(String(response.status));
    } else if (response.status === 500) {
        dispatch(error.forbiddenError({ message: 'Internal Server Error' }));
        dispatch(
            error.handleSamaritanApiError({ message: 'Internal Server Error' })
        );
        throw new Error(String(response.status));
    } else {
        throw new Error(`${response.status}: ${response.statusText}`);
    }
}

// export function handleSamaritanApiResponse(response: any, dispatch: Function) {
//     if (
//         response &&
//         (response.ok ||
//             response.status === 403 ||
//             (response.status === 400 && response.statusText === '') ||
//             response.status === 404 ||
//             response.status === 422 ||
//             response.status === 500 ||
//             response.status === 504)
//     ) {
//         console.log('response', response);
//         return response.json();
//     } else if (response && response.status === 401) {
//         dispatch(error.foundUnauthorizedUser());
//         throw new Error(String(response.status));
//     } else {
//         throw new Error(`${response.status}: ${response.statusText}`);
//     }
// }

const actions = {
    ...error,
    ...samaritan,
    ...catchUpItems,
    ...debitCard,
    ...deployments,
    ...determinantRanks,
    ...goalActions,
    ...goals,
    ...homeless,
    ...homelesses,
    ...intake,
    ...local,
    ...messages,
    ...needs,
    ...newsfeed,
    ...organization,
    ...payments,
    ...pex,
    ...referrals,
    ...skills,
    ...touchpointAnswers,
    ...touchpointQuestions,
    ...touchpoints,
    ...unenrollment,
    ...user
};

export default actions;