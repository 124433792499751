import Background from 'assets/background-card.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    cardBackground: {
        position: 'absolute',
        top: '7em',
        background: '#EAEFF3',
        borderRadius: '40px',
        height: 'calc(100% - 5em)',
        width: '100%',
        zIndex: 0
    },
    backgroundImage: {
        bottom: '0',
        borderRadius: '40px 40px 0 0',
        height: '440px',
        objectFit: 'cover',
        opacity: '0.5',
        width: '100%'
    }
}));

type CardBackgroundProps = {
    customClasses?: {
        [key: string]: {
            [key: string]: string;
        };
    };
};

const CardBackground: React.FC<CardBackgroundProps> = ({ customClasses }) => {
    const classes = useStyles();

    return (
        <div
            className={classes.cardBackground}
            style={
                customClasses?.cardBackground
                    ? customClasses.cardBackground
                    : {}
            }
        >
            <img
                className={classes.backgroundImage}
                src={Background}
                alt={'card background'}
            />
        </div>
    );
};

export default CardBackground;
