import { useCallback, useState, useEffect } from 'react';

import { Homeless, User } from 'types';

import ContactInfo from './components/ContactInfo';
import DocumentStorage from './components/Documents';
import Enrollment from './components/Enrollment';
import LoadingCircle from 'components/_shared/LoadingCircle';
import MemberInfo from './components/MemberInfo';
import PopUp from '../PopUp';
import RequestErrorModal from 'components/_shared/RequestErrorModal';

import { fetchDocumentsForId } from 'api/documents';
import useUpdatePrivateProfile from 'hooks/useUpdatePrivateProfile';

import {
    formatDate,
    formatHolderPhone,
    formatPhoneNumber,
    validateEmail,
    validatePhone
} from './utils';
import { isObjectEmpty } from 'util/index';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        privateInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '80px',
            padding: '30px 12% 60px 12%'
        },
        header: {
            margin: '10px 0'
        },
        headerTitle: {
            fontWeight: 800,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#1F2152'
        },
        headerTitleDiv: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        closeBtn: {
            padding: 0,
            minWidth: '50px',
            color: '#7378E8'
        },
        inputFields: {
            width: '100%',
            border: '0',
            padding: '10px 0px 0px 0px',
            fontFamily: 'Manrope',
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#1f1f51',
            borderRadius: '6px',
            letterSpacing: '-0.21px',
            background: 'transparent',
            resize: 'none',
            '&:placeholder-shown': {
                fontStyle: 'italic'
            },
            '&:focus': {
                outline: 'none !important'
            }
        },
        inputBirthday: {
            width: '100%',
            border: '0',
            padding: '5px 0px 0px 0px',
            fontFamily: 'Manrope',
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#1f1f51',
            borderRadius: '6px',
            letterSpacing: '-0.21px',
            background: 'transparent',
            '&:placeholder-shown': {
                fontStyle: 'italic'
            },
            '&:focus': {
                outline: 'none !important'
            }
        },
        underline: {
            height: '0',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            marginBottom: '2rem'
        },
        underlineError: {
            height: '0',
            borderRadius: '10px',
            marginBottom: '2rem',
            border: '6px solid #FF0000'
        },
        body: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            borderRadius: '16px',
            padding: '5px 24px 5px',
            gap: '32px',
            marginTop: '2rem'
        },
        bodyHeader: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '32px',
            color: '#1F2152'
        },
        btnDiv: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '32px',
            marginTop: '2rem'
        },
        btn: {
            margin: '10px auto 30px auto',
            minWidth: '300px',
            fontSize: '20px'
        },
        editButtonDiv: {},
        editButton: {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            height: 40,
            width: 40,
            minWidth: 40,
            borderRadius: 40,
            outline: 'none',
            fontSize: '1.2rem',
            color: '#2B3165',
            border: 'none',
            background: '#FFFFFF',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)'
        },
        marginBottom: {
            marginBottom: '2rem'
        },
        name: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '33px',
            color: '#1F1F51',
            margin: 0,
            marginBottom: '2rem'
        },
        orgName: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '33px',
            color: '#1F1F51',
            margin: 0
        },
        title: {
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '100%',
            display: 'flex',
            alignTtems: 'flex-end',
            letterSpacing: '0.15em',
            textTransform: 'uppercase',
            color: '#1F2051',
            opacity: 0.7,
            marginBottom: '0.5rem'
        },
        orgLogo: {
            width: '32px',
            height: '32px',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            borderRadius: '50%',
            marginRight: '12px'
        },
        holderOrgDiv: {
            display: 'flex',
            marginBottom: '2rem'
        },
        editBirthdayDiv: {
            boxSizing: 'border-box',
            width: '100%',
            padding: 16,
            marginTop: 10,
            borderRadius: 20,
            borderWidth: 1,
            outline: 'none',
            fontSize: '1rem',
            fontFamily: 'Manrope, sans-serif',
            marginBottom: '2rem',
            backgroundColor: 'white',
            border: '1px solid rgb(118, 118, 118)'
        },
        memberInfoContainer: {},
        contactInfoContainer: {},
        language: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '33px',
            color: '#1F1F51'
        },
        languageField: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            paddingTop: '10px'
        }
    })
);

type PrivateInfoProps = {
    canConfirmMembership: boolean;
    exited: boolean;
    holder: Homeless;
    setConfirm: (value: boolean) => void;
    setUnenroll: (value: boolean) => void;
    setReenroll: (value: boolean) => void;
    unsuccessful: boolean;
    updateProfileCompletedItems: (type: string) => void;
    user: User;
};

const PrivateInfo: React.FC<PrivateInfoProps> = ({
    canConfirmMembership,
    exited,
    holder,
    setConfirm,
    setReenroll,
    setUnenroll,
    unsuccessful,
    updateProfileCompletedItems,
    user
}) => {
    const classes = useStyles();

    const {
        error,
        isError,
        isLoading,
        isSuccess,
        reset,
        updatePrivateProfile
    } = useUpdatePrivateProfile();

    const {
        birthday,
        deployment_id,
        email,
        first_name,
        id,
        middle_name,
        name,
        language_preference,
        last_name,
        member_id,
        phone_number,
        qr_code_id
    } = holder;

    const [newBirthday, setBirthday] = useState(birthday ? birthday : null);
    const deployments = user?.partner?.deployments || [];
    const holderDeployment =
        deployments.find((dep) => dep.id === deployment_id)?.id || null;

    const [newDeployment, setNewDeployment] = useState(
        holderDeployment ? holderDeployment : null
    );
    const [newEmail, setEmail] = useState(email ? email : null);
    const [newFirstName, setFirstName] = useState(
        first_name ? first_name : null
    );
    const [newIdentificationNumber, setIdentificationNumber] = useState(
        member_id ? member_id : null
    );
    const [newLanguage, setNewLanguage] = useState(
        language_preference ? language_preference : null
    );
    const [newLastName, setLastName] = useState(last_name ? last_name : null);
    const [newMiddleName, setMiddleName] = useState(
        middle_name ? middle_name : null
    );
    const [newPhoneNumber, setPhoneNumber] = useState(
        formatHolderPhone(phone_number ? phone_number : null)
    );
    const [newQR, setQR] = useState(qr_code_id ? qr_code_id : null);
    const [updateLanguage, setUpdateLanguage] = useState(false);

    const [documentList, setDocumentList] = useState<null | object[]>(null);
    const [isEditingContactInfo, setIsEditingContactInfo] = useState(false);
    const [isEditingMemberInfo, setIsEditingMemberInfo] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchDocs = useCallback(
        () =>
            fetchDocumentsForId(Number(id))
                .then((res: Response) => res.json())
                .then((items: { holder_documents: object[] }) => {
                    setDocumentList(items.holder_documents);
                    setLoading(false);
                }),
        [id]
    );

    useEffect(() => {
        if (!documentList) {
            fetchDocs();
        }
    }, [documentList, fetchDocs]);

    const handleSaveMemberInfo = useCallback(() => {
        const changedState: Partial<
            Record<keyof typeof fields, string | number | null>
        > = {};
        const formData = new FormData();

        const fields = {
            first_name: newFirstName,
            middle_name: newMiddleName,
            last_name: newLastName,
            birthday: newBirthday,
            deployment_id: newDeployment,
            id_num: newIdentificationNumber,
            qr_id: newQR
        };

        const holderFields = {
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
            birthday: birthday,
            deployment_id: holderDeployment,
            id_num: member_id,
            qr_id: qr_code_id
        };

        (Object.keys(fields) as Array<keyof typeof fields>).forEach((key) => {
            const fieldValue = fields[key];
            const holderValue = holderFields[key];

            if (fieldValue !== holderValue) {
                changedState[key] = fieldValue;
            }
        });

        const appendFormData = (
            fieldKey: keyof typeof fields,
            formKey: string,
            completedItem?: string
        ) => {
            const value = changedState[fieldKey];

            if (fieldKey in changedState && value !== '') {
                formData.append(formKey, String(value ?? ''));

                if (completedItem) {
                    updateProfileCompletedItems(completedItem);
                }
            }
        };

        if (!isObjectEmpty(changedState)) {
            appendFormData(
                'birthday',
                'samaritan_member[date_of_birth]',
                'birthday'
            );
            appendFormData('deployment_id', 'samaritan_member[deployment_id]');
            appendFormData(
                'first_name',
                'samaritan_member[pii_first_name]',
                'full_name'
            );
            appendFormData(
                'last_name',
                'samaritan_member[pii_last_name]',
                'full_name'
            );
            appendFormData('middle_name', 'samaritan_member[pii_middle_name]');
            appendFormData(
                'id_num',
                'samaritan_member[pii_member_id]',
                'member_id'
            );
            appendFormData('qr_id', 'samaritan_member[qr_code_id]');
            updatePrivateProfile(id, formData);
        }
    }, [
        birthday,
        first_name,
        last_name,
        member_id,
        middle_name,
        qr_code_id,
        holderDeployment,
        id,
        newBirthday,
        newDeployment,
        newFirstName,
        newIdentificationNumber,
        newLastName,
        newMiddleName,
        newQR,
        updatePrivateProfile,
        updateProfileCompletedItems
    ]);

    const handleSaveContactInfo = useCallback(() => {
        const changedState: Partial<
            Record<keyof typeof fields, string | number | null>
        > = {};
        const formData = new FormData();

        const fields = {
            phone: newPhoneNumber,
            email: newEmail,
            language: newLanguage
        };

        const holderFields = {
            phone: formatHolderPhone(phone_number),
            email: email,
            language: language_preference
        };

        const validators: { [key: string]: (value: string) => boolean } = {
            phone: validatePhone,
            email: validateEmail
        };

        Object.entries(fields).forEach(([key, value]) => {
            if (
                value !== holderFields[key as keyof typeof holderFields] &&
                (!validators[key] || validators[key](value as string))
            ) {
                changedState[key as keyof typeof fields] = value;
            }
        });

        const appendFormData = (
            fieldKey: keyof typeof fields,
            formKey: string,
            completedItem?: string
        ) => {
            if (fieldKey in changedState) {
                let fieldValue = changedState[fieldKey] ?? '';

                if (fieldKey === 'phone') {
                    fieldValue =
                        typeof fieldValue === 'string'
                            ? `+1${fieldValue.replace(/\D/g, '')}`
                            : '';
                }

                formData.append(formKey, fieldValue as string);

                if (completedItem) {
                    updateProfileCompletedItems(completedItem);
                }
            }
        };

        if (!isObjectEmpty(changedState)) {
            appendFormData('email', 'samaritan_member[email]', 'contact_info');
            appendFormData('language', 'samaritan_member[language_preference]');
            appendFormData('phone', 'samaritan_member[phone]', 'contact_info');

            updatePrivateProfile(id, formData);
        }
    }, [
        email,
        language_preference,
        phone_number,
        id,
        newEmail,
        newLanguage,
        newPhoneNumber,
        updatePrivateProfile,
        updateProfileCompletedItems
    ]);

    const editMemberProps = {
        newFirstName,
        setFirstName,
        newMiddleName,
        setMiddleName,
        newLastName,
        setLastName,
        newBirthday,
        setBirthday,
        deployments,
        newDeployment,
        setNewDeployment,
        newIdentificationNumber,
        setIdentificationNumber,
        newQR,
        setQR,
        handleSave: handleSaveMemberInfo
    };

    const editContactProps = {
        holder,
        newEmail,
        setEmail,
        newLanguage,
        setNewLanguage,
        newPhoneNumber,
        setPhoneNumber,
        handleSave: handleSaveContactInfo,
        updateLanguage,
        setUpdateLanguage,
        validateEmail,
        validatePhone
    };

    useEffect(() => {
        if (isSuccess && (isEditingContactInfo || isEditingMemberInfo)) {
            reset();

            setIsEditingContactInfo(false);
            setIsEditingMemberInfo(false);
        }
    }, [isSuccess, reset, isEditingContactInfo, isEditingMemberInfo]);

    if (isLoading) {
        return (
            <div className={classes.privateInfoContainer}>
                <div style={{ width: '100%', height: '100px' }}>
                    <LoadingCircle>Saving Changes</LoadingCircle>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.privateInfoContainer}>
            {isError && (
                <RequestErrorModal
                    open={isError}
                    onClose={() => {
                        reset();
                    }}
                    message={`Save error: ${error}`}
                    retryFunc={
                        isEditingContactInfo
                            ? handleSaveContactInfo
                            : handleSaveMemberInfo
                    }
                />
            )}
            <MemberInfo
                classes={classes}
                deployments={deployments}
                exited={exited}
                formatDate={formatDate}
                holder={holder}
                setIsEditing={setIsEditingMemberInfo}
            />
            <ContactInfo
                classes={classes}
                exited={exited}
                formatPhoneNumber={formatPhoneNumber}
                holder={holder}
                setIsEditing={setIsEditingContactInfo}
            />
            {(((exited || unsuccessful) && documentList?.length) ||
                (!exited && !unsuccessful)) && (
                <DocumentStorage
                    documentList={documentList}
                    exited={exited}
                    fetchDocs={fetchDocs}
                    holder={name}
                    loading={loading}
                />
            )}
            {!unsuccessful && (
                <Enrollment
                    canConfirmMembership={canConfirmMembership}
                    holder={holder}
                    setConfirm={setConfirm}
                    setReenroll={setReenroll}
                    setUnenroll={setUnenroll}
                />
            )}
            {isEditingMemberInfo && (
                <PopUp
                    classes={classes}
                    content="edit member info"
                    handleModalClick={setIsEditingMemberInfo}
                    {...editMemberProps}
                />
            )}
            {isEditingContactInfo && (
                <PopUp
                    classes={classes}
                    content="edit contact info"
                    handleModalClick={setIsEditingContactInfo}
                    {...editContactProps}
                />
            )}
        </div>
    );
};

export default PrivateInfo;
