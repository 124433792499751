import { useCallback, useEffect, useState } from 'react';

import AddInfo from '../../ProfileCompletion/components/AddInfo';
import Card from '../../CaseManagement/components/Card';
import DebitView from './Views/DebitView';
import LoadingCircle from 'components/_shared/LoadingCircle';
import PINView from './Views/PINView';
import TransferView from './Views/TransferView';
import UpdateModal from 'components/_shared/UpdateModal';
import { BackButton } from './Buttons';
import { CTAButton1 } from 'components/_shared/buttons';

import { useDebitCardActions } from 'hooks/useDebitCardActions';

import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    assignBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 0 0 0'
    },
    assignBtn: {
        margin: '8px 0 0 0',
        height: '60px',
        background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
        fontSize: '16px'
    },
    assignInstructions: {
        position: 'relative',
        top: '-16px',
        background: '#E7EFFE',
        borderRadius: '0 0 16px 16px',
        color: '#062D7A',
        fontSize: '16px',
        fontWeight: '500',
        maxWidth: '320px',
        padding: '24px 0 16px 0',
        textAlign: 'center'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        margin: 'auto',
        width: '55%'
    },
    cardContainer: {
        display: 'flex',
        '& #title': {
            alignSelf: 'center',
            paddingLeft: '8px'
        }
    },
    cardWrapper: {
        margin: 'auto'
    },
    disableBtn: {
        opacity: '0.5'
    },
    dividingLine: {
        boxSizing: 'border-box',
        maxWidth: '100%',
        marginInline: 20,
        height: 1,
        background: '#E3E3FA',
        marginTop: 15
    },
    errorBtn: {
        background: 'linear-gradient(180deg, #F5577A 0%, #F5577A 100%)'
    },
    errorMessage: {
        color: '#F5577A !important'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: '800'
    },
    loading: {
        background: 'rgba(255, 255, 255, 0.5)'
    },
    messageContainer: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
        color: ' #1F2152',
        textAlign: 'center',
        margin: 'auto',
        padding: '8px 0'
    },
    numPadWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .row': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 16px 0px 16px',
            '& .btn': {
                height: '56px',
                width: '56px',
                fontFamily: 'Manrope',
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '31px',
                color: '#1F2152'
            }
        }
    },
    successBtn: {
        background: 'linear-gradient(180deg, #7FE8B2 0%, #7FE8B2 100%)'
    },
    successMessage: {
        color: '#7FE8B2'
    }
}));

type NumPadRequiredProps = {
    assignView: boolean;
    id: number;
    name: string;
    setAssignView: (assignView: boolean) => void;
};

type NumPadOptionalProps = {
    balance: number;
    catchUpID?: number;
    changeView: boolean;
    debitCardOptions: any;
    debitType: null | 'givecard' | 'pex';
    fromCatchUp: boolean;
    handleModalClick: Function;
    replaceCardView: boolean;
    replaceView: boolean;
    setAmount: (amount: number) => void;
    setAssignDebit: (assignDebit: boolean) => void;
    setChangeView: (changeView: boolean) => void;
    setDebitType: (type: 'givecard' | 'pex' | null) => void;
    setReplaceView: (replaceView: boolean) => void;
    setTransferView: (transferView: boolean) => void;
    transferView: boolean;
    updatePinView: boolean;
};

type NumPadProps = NumPadRequiredProps & Partial<NumPadOptionalProps>;

export default function NumPad({
    assignView,
    balance,
    catchUpID,
    changeView,
    debitCardOptions,
    debitType,
    fromCatchUp,
    handleModalClick,
    id,
    name,
    replaceCardView,
    replaceView,
    setAssignDebit,
    setAssignView,
    setChangeView,
    setDebitType,
    setReplaceView,
    setTransferView,
    transferView,
    updatePinView
}: NumPadProps) {
    const classes = useStyles();

    const {
        assignDebitCard,
        debitInfo,
        error: debitError,
        errorMessage,
        loading: debitLoading,
        resetDebitState: reset,
        success: debitSuccess,
        transferDebitBalance,
        updateDebitPin
    } = useDebitCardActions();

    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(false);
    const [externalID, setExternalID] = useState(``);
    const [externalIDPopUp, setExternalIDPopUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(``);
    const [nums, setNums] = useState(``);
    const [success, setSuccess] = useState(false);

    const inputLayout = [`1 2 3`, `4 5 6`, `7 8 9`, `. 0 <`];

    // TODO: Type the event
    const keyInputs = (e: any) => {
        if (isFinite(e.key as number)) {
            if (!transferView) {
                if (nums.length < 4) {
                    setNums(nums + e.key);
                }
                validateDebitNum(nums);
            } else {
                if (
                    (nums.length === 0 && e.key === `.`) ||
                    (nums.length === 0 && e.key === `0`) ||
                    (nums.includes(`.`) && e.key === `.`) ||
                    (nums.includes(`.`) &&
                        nums.split(`.`)[1].length === 2 &&
                        e.key !== `Delete`)
                ) {
                    return;
                }

                if (nums.length < 4) {
                    setNums(nums + e.key);
                }
            }
        }

        if (e.key === `Backspace` || e.key === `Delete`) {
            setNums(nums.slice(0, nums.length - 1));
        }
    };

    const numberInput = (button: string) => {
        if (debitError) {
            reset();
            setNums(``);
        }

        if (!transferView) {
            if (button === `<`) {
                setNums(nums.slice(0, nums.length - 1));
            } else {
                if (nums.length < 4) {
                    setNums(nums + button);
                }
                validateDebitNum(nums);
            }
        } else {
            if (button === `<`) {
                setNums(nums.slice(0, nums.length - 1));
            } else {
                if (
                    (nums.length === 0 && button === `.`) ||
                    (nums.length === 0 && button === `0`) ||
                    (nums.includes(`.`) && button === `.`) ||
                    (nums.includes(`.`) &&
                        nums.split(`.`)[1].length === 2 &&
                        button !== `<`) ||
                    balance === 0
                ) {
                    return;
                }

                if (!nums.includes(`.`) && nums.length < 4) {
                    setNums(nums + button);
                } else {
                    if (button === `.`) {
                        setNums(nums + button);
                    }

                    if (nums.includes(`.`) && nums.length < 7) {
                        setNums(nums + button);
                    }
                }
            }
        }

        setError(false);
        setSuccess(false);
        setMessage(``);
    };

    const validateDebitNum = useCallback(
        (num: string) => {
            if (balance === 0 && transferView) {
                setDisabled(true);
                return false;
            }

            if (/^\d+$/.test(num) && num.length > 3 && debitType) {
                setDisabled(false);
                setError(false);
                setSuccess(false);
                return true;
            } else {
                if (!transferView) {
                    setDisabled(true);
                }
                return false;
            }
        },
        [balance, debitType, transferView]
    );

    const assignDebit = async (id: number) => {
        if (debitError) {
            reset();
            setError(false);
        }

        try {
            await assignDebitCard(id, debitType as 'givecard' | 'pex', nums, {
                catchUpItemID: catchUpID
            });
        } catch (error) {
            setError(true);
            setMessage(errorMessage);
            setNums(``);
            setExternalID(``);
        }
    };

    const updatePIN = async (id: number) => {
        if (debitError) {
            reset();
            setError(false);
        }

        try {
            await updateDebitPin(id, parseInt(nums));
        } catch (error) {
            setError(true);
            setMessage(errorMessage);
            setNums(``);
        }
    };

    const transferBalance = async (id: number) => {
        if (!debitType) {
            return;
        }

        try {
            await transferDebitBalance(id, parseInt(nums), catchUpID);
        } catch (error) {
            setError(true);
            setMessage(errorMessage);
            setNums(``);
        }
    };

    const buttonStyles = () => {
        if (error) {
            return `${classes.assignBtn} ${classes.errorBtn}`;
        }

        if (debitSuccess || success) {
            return `${classes.assignBtn} ${classes.successBtn}`;
        }

        if (loading) {
            return `${classes.assignBtn} ${classes.loading}`;
        }

        if (disabled) {
            return `${classes.assignBtn} ${classes.disableBtn}`;
        }

        return classes.assignBtn;
    };

    const messageStyles = () => {
        if (error) {
            return `${classes.messageContainer} ${classes.errorMessage}`;
        }

        if (debitSuccess || success) {
            return `${classes.messageContainer} ${classes.successMessage}`;
        }

        return classes.messageContainer;
    };

    const handleDisplayForm = () => {
        if (handleModalClick && (replaceCardView || updatePinView)) {
            handleModalClick();
        }

        if (assignView) {
            setAssignView(false);
        }

        if (changeView && setChangeView) {
            setChangeView(false);
        }

        if (replaceView && setReplaceView) {
            setReplaceView(false);
        }

        if (transferView && setTransferView) {
            setTransferView(false);
        }
    };

    const handleExternalIDModalClick = () => {
        setError(false);
        setExternalIDPopUp(false);
        setExternalID(``);
        setMessage(``);
        reset();
    };

    const handleSubmitForm = (id: number) => {
        if (assignView || replaceView) {
            assignDebit(id);
        }

        if (changeView) {
            updatePIN(id);
        }

        if (transferView) {
            transferBalance(id);
        }
    };

    const renderErrorMessage = () => {
        if (
            debitType === 'givecard' &&
            message === 'too many possible cards for last4'
        ) {
            return;
        }

        return message;
    };

    const renderHeader = () => {
        if (changeView) {
            return `Update PIN`;
        }

        if (transferView) {
            return `Transfer - Debit Card ${debitInfo.card_last4}`;
        }

        return `Assigning ${
            debitType === `givecard` ? `GiveCard` : `PEX Card`
        }`;
    };

    const renderView = () => {
        if (changeView) {
            return <PINView nums={nums} />;
        }

        if (transferView) {
            return (
                <TransferView
                    cardBalance={debitInfo.available_balance}
                    memberBalance={balance!}
                    nums={nums}
                />
            );
        }

        return (
            <DebitView
                debitCardOptions={debitCardOptions}
                debitType={debitType!}
                nums={nums}
                setDebitType={setDebitType!}
            />
        );
    };

    const renderSubmitLabel = () => {
        if (loading) {
            return <LoadingCircle style={{ paddingTop: '10px' }} />;
        }

        if (changeView && !success) {
            return `Update PIN`;
        }

        if (error) {
            return `Error!`;
        }

        if (success) {
            return `Success!`;
        }

        if (transferView && !success) {
            return `Transfer`;
        }

        return `Assign Debit Card`;
    };

    useEffect(() => {
        if (!transferView) {
            if (nums.length < 4) {
                setDisabled(true);
            }
        } else {
            if (parseInt(nums) > 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }

            // if amount is > member balance, sets amount to member balance
            if (parseInt(nums) > balance!) {
                setNums(balance!.toString());
            }
        }
    }, [balance, debitType, error, nums, transferView]);

    useEffect(() => {
        if (debitType && validateDebitNum(nums)) {
            setDisabled(false);
        }
    }, [debitType, nums, validateDebitNum]);

    useEffect(() => {
        setLoading(debitLoading);
    }, [debitLoading]);

    useEffect(() => {
        if (debitError) {
            setError(debitError);
            setMessage(errorMessage);
        }
    }, [debitError, debitType, errorMessage]);

    useEffect(() => {
        if (debitSuccess) {
            const { card_last4, pex_card_last4 } = debitInfo;

            if (assignView) {
                setMessage(
                    `Card ending in ${
                        debitType === 'pex' ? pex_card_last4 : card_last4
                    } assigned to ${name} 🎉`
                );
            }

            if (changeView) {
                setMessage(`PIN Updated 🎉`);
            }

            if (transferView) {
                setMessage(`$${nums} transferred to debit card 🎉`);
            }

            if (updatePinView) {
                setMessage(`PIN Updated 🎉`);
            }

            setSuccess(debitSuccess);

            if (externalIDPopUp) {
                setError(false);
                setExternalIDPopUp(false);
                setMessage(
                    `Card ending in ${card_last4} assigned to ${name} 🎉`
                );
            }

            setTimeout(() => {
                if (assignView) {
                    setAssignView(false);
                    handleModalClick!();
                }

                if (setChangeView && changeView) {
                    setChangeView(false);
                }

                if (setTransferView && transferView) {
                    setTransferView(false);
                }

                if (setAssignDebit || replaceCardView || replaceView) {
                    if (setAssignDebit) {
                        setAssignDebit(true);
                    }

                    handleModalClick!();
                }

                reset();
            }, 1500);
        }
    }, [
        assignView,
        changeView,
        externalIDPopUp,
        transferView,
        updatePinView,
        debitInfo,
        debitSuccess,
        debitType,
        handleModalClick,
        name,
        nums,
        replaceView,
        replaceCardView,
        reset,
        setAssignDebit,
        setAssignView,
        setChangeView,
        setReplaceView,
        setTransferView
    ]);

    useEffect(() => {
        if (message === 'too many possible cards for last4') {
            setExternalIDPopUp(true);
            reset();
        }
    }, [errorMessage, message, reset]);

    useEffect(() => {
        setMessage(``);
        setError(false);
    }, [debitType]);

    return (
        <Card style={{ margin: 0, position: 'relative' }}>
            <div className={classes.header}>
                {!assignView && !fromCatchUp ? (
                    <BackButton handleClick={() => handleDisplayForm()} />
                ) : null}
                <span style={{ marginLeft: '16px' }}>{renderHeader()}</span>
            </div>
            <div className={classes.dividingLine} />
            <div className={classes.assignBody}>
                {renderView()}
                {assignView && (
                    <span className={classes.assignInstructions}>
                        Enter the last 4 digits of your card number to activate
                    </span>
                )}
            </div>
            {message.length > 0 && (
                <div className={messageStyles()}>{renderErrorMessage()}</div>
            )}
            <div className={classes.cardBody}>
                <div className={classes.numPadWrapper}>
                    {inputLayout.map((row, i) => {
                        return (
                            <div key={i} className="row">
                                {row.split(' ').map((button, j) => {
                                    return (
                                        <IconButton
                                            key={j}
                                            className={`btn`}
                                            disabled={
                                                button === `.` && !transferView
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => numberInput(button)}
                                            onKeyDown={(e) => keyInputs(e)}
                                            size="large"
                                            style={
                                                button === `.` && !transferView
                                                    ? { cursor: 'default' }
                                                    : { cursor: 'pointer' }
                                            }
                                        >
                                            {button === `<` ? (
                                                <i
                                                    className="far fa-backspace"
                                                    style={{ color: '#7378E8' }}
                                                />
                                            ) : button === `.` &&
                                              !transferView ? (
                                                ` `
                                            ) : (
                                                button
                                            )}
                                        </IconButton>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <CTAButton1
                        className={buttonStyles()}
                        disabled={disabled || loading || success}
                        onClick={() => handleSubmitForm(id)}
                    >
                        {renderSubmitLabel()}
                    </CTAButton1>
                </div>
            </div>
            {externalIDPopUp && (
                <UpdateModal
                    closeModalClick={() => handleExternalIDModalClick()}
                    title="External ID"
                >
                    <AddInfo
                        debitType={debitType}
                        externalID={externalID}
                        id={id}
                        nums={nums}
                        setExternalID={setExternalID}
                        type="external_id"
                    />
                </UpdateModal>
            )}
        </Card>
    );
}
