import Confetti from '../../../../_shared/Confetti';
import CompleteProfileProgress from '../../Header/components/CompleteProfileProgress';
import ProfileItem from './ProfileItem';

import { ProfileItems } from '../../../../../types';

import { possessiveSuffix } from '../../../../../util';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        body: {
            position: 'relative',
            background: '#FBFBFF',
            borderRadius: '0 0 16px 16px',
            boxSizing: 'border-box',
            padding: '24px 24px 0 24px',
            width: '100%'
        },
        bodyItems: {
            position: 'relative',
            top: '-56px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#EBEBFF',
            borderRadius: '16px',
            color: '#1F2152',
            paddingTop: '64px'
        },
        photoContainer: {
            position: 'absolute',
            left: 'calc(50% - 50px)',
            top: '-48px',
            borderRadius: '50%',
            height: '100px',
            width: '100px',
            '& img': {
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
                width: '100%'
            }
        },
        subtitle: {
            fontWeight: 300,
            paddingBottom: '48px',
            '& b': {
                fontWeight: 900
            }
        }
    })
);

type DefaultBodyProps = {
    name: string;
    photo: string;
    profileItems: ProfileItems;
    setType: (type: string) => void;
};

const DefaultBody: React.FC<DefaultBodyProps> = ({
    name,
    photo,
    profileItems,
    setType
}) => {
    const classes = useStyles();

    const profilePercentage =
        (Object.keys(profileItems).filter(
            (item) =>
                profileItems[item].status === 'complete' ||
                profileItems[item].status === 'pending'
        ).length /
            Object.keys(profileItems).length) *
        100;
    const profileRemainingItems = Object.keys(profileItems).filter(
        (item) =>
            profileItems[item].status !== 'complete' &&
            profileItems[item].status !== 'pending'
    );
    const pendingItems = Object.keys(profileItems).filter(
        (item) => profileItems[item].status === 'pending'
    );
    const profileRemainingItems2 = Object.keys(profileItems).filter(
        (item) => profileItems[item].status !== 'complete'
    );

    const renderProfileItems = () => {
        return Object.keys(profileItems).map((item) => {
            if (profileItems[item].status !== 'complete') {
                return (
                    <ProfileItem
                        key={item}
                        item={item}
                        setType={setType}
                        status={profileItems[item].status}
                    />
                );
            }

            return null;
        });
    };

    const renderRemainingItemsText = () => {
        if (profileRemainingItems.length === 0) {
            return 'You have completed all remaining steps!';
        }

        if (pendingItems.length === 0) {
            return (
                <>
                    You have <b>{profileRemainingItems.length}</b> remaining
                    steps
                </>
            );
        }

        return (
            <>
                You have completed{' '}
                <b>
                    {pendingItems.length} out of {profileRemainingItems2.length}
                </b>{' '}
                remaining steps
            </>
        );
    };

    return (
        <div
            className="default-body-container"
            style={{ position: 'relative' }}
        >
            <div className={classes.photoContainer}>
                <img src={photo} alt="profile" />
                <CompleteProfileProgress
                    percentage={profilePercentage}
                    popupStyle
                />
            </div>
            <div className={classes.content}>
                {profilePercentage === 100 && (
                    <Confetti
                        popupStyle
                        stopConfetti={profilePercentage !== 100}
                    />
                )}
                <h2 style={{ margin: '1rem 0 0.5rem 0' }}>
                    Complete {possessiveSuffix(name)} Profile
                </h2>
                <span className={classes.subtitle}>
                    {renderRemainingItemsText()}
                </span>
                <div className={classes.body}>
                    <div className={classes.bodyItems}>
                        {renderProfileItems()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DefaultBody;
