import {
    CTAButton1,
    QuickAction,
    SecondaryButton
} from '../../_shared/buttons';
import FormInputTextWithLabelSpecial from './FormInputTextWithLabelSpecial';
import classes from './index.module.scss';
import { SAMARITAN_URL } from '../../../settings';

export const openInNewTab = (subdomain: string) => {
    const url_env = SAMARITAN_URL.includes('staging') ? 'staging.' : '';
    const url = `https://${subdomain}.${url_env}samaritan.city/users/password/new`;

    window.open(url, '_blank', 'noreferrer');
};

const forgotPasswordDiv = {
    display: 'inline-block',
    width: '100%'
};
const title = {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 400,
    margin: '0px 2px 0px 0px'
};
const button = {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 800,
    cursor: 'pointer',
    border: '2px solid #fff',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    color: '#fff',
    lineHeight: '1.25rem',
    padding: '0.2em',
    background: 'none'
};

type LoginInputsProps = {
    inputsRef: React.MutableRefObject<HTMLDivElement | null>;
    setStage: (stage: string) => void;
    setCity: (city: any) => void;
    name: string | undefined;
    subdomain: string | undefined;
    error: boolean;
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (password: string) => void;
    loading: boolean;
    stage: string;
};

export const LoginInputs = ({
    inputsRef,
    setStage,
    setCity,
    name,
    subdomain,
    error,
    email,
    setEmail,
    password,
    setPassword,
    loading,
    stage
}: LoginInputsProps) => (
    <div className={classes.formInputsDiv} ref={inputsRef}>
        <div>
            <QuickAction
                type="reset"
                icon="chevron-left"
                small
                invert
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setStage('cities');
                    setCity({});
                }}
            />
            <span style={{ marginLeft: 5 }}>{name}</span>
        </div>
        <div>
            <div>{!!error && 'Incorrect username or password'}</div>
            <div>
                <FormInputTextWithLabelSpecial
                    autoComplete="email"
                    error={!!error}
                    label="email"
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <FormInputTextWithLabelSpecial
                    autoComplete="current-password"
                    error={!!error}
                    label="password"
                    type="password"
                    id="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
        </div>
        <div style={forgotPasswordDiv}>
            <h5 style={title}>Forgot your password?</h5>
            <button
                style={button}
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openInNewTab(subdomain!);
                }}
            >
                Get a reset link
            </button>
        </div>
        <div
            style={{
                textAlign: 'center',
                marginTop: 40
            }}
        >
            <CTAButton1
                invert
                type="submit"
                style={{
                    paddingBlock: '0.6em',
                    outline: 'none'
                }}
            >
                {loading ? 'signing in' : 'sign in'}
            </CTAButton1>
            <div
                style={{
                    marginTop: 5,
                    paddingBottom: stage === 'signUp' ? 40 : 0
                }}
            >
                <div style={{ marginTop: '5px' }}>
                    or{' '}
                    <SecondaryButton
                        style={{ fontWeight: 800 }}
                        light
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setStage('signUp');
                        }}
                    >
                        Create an account
                    </SecondaryButton>
                </div>
            </div>
        </div>
    </div>
);
